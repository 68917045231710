import classNames from "classnames";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import React, { Component, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";

import { getURLParams } from "../../helpers/utils";
import logo from "../../static/assets/logo/privateporch-logo.png";
import logoStacked from "../../static/assets/logo/privateporch-logo-stacked.png";
import AppContent from "./AppContent";
import AppFooter from "./AppFooter";
import AppMenu from "./AppMenu/AppMenu";
import AppMenuButton from "./AppMenu/AppMenuButton";
// import AppMetadata from "./AppMetadata";
import AppNav from "./AppNav";
import AppNavItem from "./AppNavItem";
import AppProfileMenu from "./AppProfileMenu";
import AppBar from "@material-ui/core/AppBar";
import common from "@material-ui/core/colors/common";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import Helmet from "react-helmet";
import { withRouter } from "react-router";
import Snackbar from "../../containers/Components/Snackbar";
const { white } = common;

const isClient = typeof window !== "undefined";
if (isClient) {
  ReactGA.initialize(process.env.RAZZLE_APP_GA_KEY);
}
const logRocketId = process.env.RAZZLE_APP_LOGROCKET_APP_ID;
if (logRocketId) {
  LogRocket.init(logRocketId);
  setupLogRocketReact(LogRocket);
}

const styles = (theme) => {
  return {
    // "@global": {
    //   body: {
    //     margin: 0,
    //     background: false ? "#F4F4F4" : theme.palette.background.default,
    //     color: theme.palette.text.primary,
    //     lineHeight: "1.2",
    //     overflowX: "hidden",
    //     WebkitFontSmoothing: "antialiased", // Antialiasing.
    //     MozOsxFontSmoothing: "grayscale" // Antialiasing.,
    //   }
    // },
    appBar: {
      height: 80,
      background: "#fff",
      transition: theme.transitions.create("width"),
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.grey.medium,
      boxShadow: "none",
    },
    button: {
      height: 80,
      color: theme.palette.text.main,
      fontSize: 16,
      fontWeight: "bold",
      display: "inline-flex",
      alignContent: "center",
      paddingTop: theme.spacing(3.75),
      textDecoration: "none",
      borderTop: "3px solid transparent",
      transition: theme.transitions.create("all"),
      cursor: "pointer",
      textTransform: "none",
      "&:hover": {
        textDecoration: "none",
        color: theme.palette.primary.main,
        borderTop: `3px solid ${theme.palette.primary.main}`,
      },
    },
    buttonSpacer: {
      width: 40,
    },
    link: {
      color: "inherit",
      textDecoration: "none",
    },
    signUpSpacer: {
      width: 148,
    },
    signUpButton: {
      justifyContent: "center",
      backgroundColor: theme.palette.primary.main,
      color: white,
      width: 222,
      display: "inline-flex",
      alignContent: "center",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      "&:hover": {
        backgroundColor: theme.palette.text.main,
        borderTop: "3px solid transparent",
        color: white,
      },
    },
    toolBar: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "space-between",
      paddingLeft: 0,
      paddingRight: 0,
    },
    logo: {
      width: 200,
      marginLeft: 40,
    },
    mainButton: {
      borderRadius: 4,
      boxShadow: "none",
      textTransform: "none",
      fontSize: 16,
      margin: ".9rem 0",
      padding: "8px 18px",
      border: "1px solid",
      lineHeight: 1.5,
      backgroundColor: theme.palette.primary.main,
      color: white,
      cursor: "pointer",
      height: 48,
      width: 195,
      "&:hover": {
        backgroundColor: theme.palette.text.main,
      },
    },
    logoButton: {
      borderTop: "3px solid transparent",
      borderRadius: "0px",
      transition: theme.transitions.create("all"),
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    rightMenu: {
      display: "flex",
    },
    [theme.breakpoints.down("xs")]: {
      facebookButtonMobile: {
        display: "flex !important",
      },
      facebookButtonDesktop: {
        display: "none !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      appBar: {
        height: 65,
      },
      logo: {
        marginLeft: 0,
        width: 96,
      },
      navMenu: {
        display: "none",
      },
    },
  };
};

class App extends Component {
  state = {
    menuOpen: false,
  };

  closeMenu = () => {
    if (this.props.width === "xs" || this.props.width === "sm") {
      this.handleMenuClose();
    }
  };

  handleMenuClose = () => {
    this.setState({ menuOpen: false });
  };

  handleMenuOpen = () => {
    this.setState({ menuOpen: true });
  };

  handleAppMenuToggle = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  componentDidMount() {
    this.props.auth.setHistory(this.props.history);
    // OurWebSocket.setDispatch(this.props.dispatch);

    this.closeMenu();
    const currentPath = this.props.location.pathname;
    if (
      currentPath === "/" &&
      this.props.history &&
      this.props.history.location
    ) {
      const params = getURLParams(this.props.history.location.search);
      if (params.reset) {
        this.props.history.replace(`/reset/${params.reset}`);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // check for screen resize without reload
    if (nextProps.width !== this.props.width) {
      if (nextProps.width === "xs" || nextProps.width === "sm") {
        this.handleMenuClose();
      } else {
        this.handleMenuOpen();
      }
    }
  }

  handleHome() {
    animateScroll.scrollToTop();
  }

  render() {
    const { auth, children, classes, profile } = this.props;
    const { isAuthenticated, logout } = this.props.auth;
    const signUpClass = classNames(classes.button, classes.signUpButton);
    const currentPath = this.props.location.pathname;
    const isPropertyLanding = currentPath.substring(0, 3) === "/p/";
    let wrapperStyle = "landing";
    if (auth.isAuthenticated()) {
      wrapperStyle = "layout";
    }
    if (isPropertyLanding) {
      wrapperStyle = "property";
    }

    const userArea = this.props.auth.isAuthenticated() ? (
      <AppProfileMenu logout={logout} profile={profile} />
    ) : (
      <Fragment>
        <Hidden smDown>
          <div className={classes.buttonSpacer} />
          <AppNavItem
            title="Features"
            to="/features"
            currentPath={currentPath}
          />
          <div className={classes.buttonSpacer} />
          <AppNavItem title="Pricing" to="/pricing" currentPath={currentPath} />
          <div className={classes.buttonSpacer} />
          <AppNavItem title="About" to="/about" currentPath={currentPath} />
          <div className={classes.buttonSpacer} />
          <AppNavItem
            title="Blog"
            href="https://medium.com/@privateporch"
            currentPath={currentPath}
          />
          <div className={classes.buttonSpacer} />
          <AppNavItem title="Log In" to="/login" currentPath={currentPath} />
          <div className={classes.signUpSpacer} />
          <Link to="/signup" className={classes.link}>
            <div className={signUpClass}>Sign Up</div>
          </Link>
        </Hidden>
      </Fragment>
    );
    return (
      <Fragment>
        <Helmet>
          <title>
            Private Porch - Beautiful listing page, marketing tools for trusted guests & direct booking on your terms with no service fees.
          </title>
          <meta
            content="Share, trade or rent your home with people you know and trust. Free for everybody."
            name="description"
          />
          <meta
            content="Private Porch - Beautiful listing page, marketing tools for trusted guests & direct booking on your terms with no service fees."
            property="og:title"
          />
          <meta
            content="Share, trade or rent your home with people you know and trust. Free for everybody."
            property="og:description"
          />
        </Helmet>
        {/* <AppMetadata /> */}
        {!isPropertyLanding && (
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <IconButton
                component={Link}
                to={"/"}
                className={classes.logoButton}
                disableFocusRipple
                disableRipple
                onClick={() => this.handleHome()}
              >
                <Hidden smDown>
                  <img alt="logo" src={logo} className={classes.logo} />
                </Hidden>
                <Hidden smUp>
                  <img alt="logo" src={logoStacked} className={classes.logo} />
                </Hidden>
              </IconButton>
              <AppNav
                isAuthenticated={isAuthenticated()}
                currentPath={currentPath}
              />
              <div className={classes.rightMenu}>
                {userArea}
                <AppMenuButton
                  open={this.state.menuOpen}
                  handleToggle={this.handleAppMenuToggle}
                />
              </div>
            </Toolbar>
          </AppBar>
        )}
        <AppMenu
          closeMenu={this.handleMenuClose}
          currentPath={currentPath}
          open={this.state.menuOpen}
          openLogin={this.openLogin}
          openSignup={this.openSignup}
          isAuthenticated={isAuthenticated()}
        />
        <AppContent wrapperStyle={wrapperStyle}>{children}</AppContent>
        <AppFooter
          isAuthenticated={isAuthenticated()}
          currentPath={currentPath}
        />
        <Snackbar />
      </Fragment>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

const withTracker = (WrappedComponent, options = {}) => {
  if (!isClient) {
    return WrappedComponent;
  }
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  const HOC = (props) => {
    useEffect(() => {
      trackPage(props.location.pathname + props.location.search);
    }, [props.location.pathname, props.location.search]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withRouter(
  connect(
    (a) => {
      return { profile: (a.app && a.app.auth && a.app.auth.data) || {} };
    },
    (dispatch) => ({ dispatch })
  )(withTracker(withStyles(styles)(App)))
);
