import { getErrorMessage } from "../../helpers/utils";
import Resource from "./resource";

let resource = new Resource({
  singularName: "guestMessage",
  pluralName: "guestMessages",
});

export function sendMessages(userIds, message, subject, propertyId) {
  return async function (dispatch, getState, api) {
		dispatch(resource.fetchingSingular);
    try {
			let data = {
				property_id: propertyId,
        		user_ids: userIds.map((id) => ({ id })),
				message,
				subject,
				resend: false
			};
      await api.rpcRequest("send-messages", data).then((response) => {
				if (response.errors) {
					let error = getErrorMessage(response);
          dispatch(resource.errorUpdatingSingular(error));
          throw error;
        }
        dispatch(resource.successUpdatingPlural());
      });
    } catch (error) {
      dispatch(resource.errorSingular(error));
      throw error;
    }
  };
}

export function updateGuestMessage(id) {
	return function (dispatch, getState, api) {
		dispatch(resource.updatingSingular());
		const guestMessage = api
			.apiAxiosRequest(`guestMessages/${id}`, "PATCH", {}, {}, true, false)
			.then((response) => {
				dispatch(resource.successUpdatingSingular(response.data));
				return response.data;
			})
			.catch((error) => {
				dispatch(resource.errorUpdatingSingular(error));
				throw error;
			});
		return guestMessage;
	};
}

export function fetchGuestMessages() {
	return function (dispatch, getState, api) {
		dispatch(resource.fetchingPlural);
		return api
			.apiRequest(`guestMessages`, "GET")
			.then(response => {
				dispatch(resource.addingPlural(response));
				let ids = response.data.map(p => p.id);
				return ids;
			}).then(ids => {
				dispatch(resource.successPlural(ids));
			})
			.catch(error => dispatch(resource.errorPlural(error)));
	}
}

export default resource.reducer;
