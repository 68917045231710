import { appSnackbarMessage } from "./redux/modules/app.js";
import { addFeedFromPushNotification } from "./redux/modules/feeds";
import { fetchNotifications } from "./redux/modules/notifications";
import ReconnectingWebSocket from "reconnecting-websocket";

class OurWebSocket {
  constructor(token) {
    this.dispatch = () => console.warn("dispatch in websocket is not set yet");
    this.token = token;
    this.loadWS(token);
  }

  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  setApi(api) {
    this.api = api;
  }

  sendMessage(message) {
    this.ws.send(message);
  }

  getNotificationMessage(type) {
    switch (type) {
      case "PropertyFriendAdd":
        return "You have been added to a property";
      case "PropertyFollowRequest":
        return "You have received a new follow request";
      case "PropertyFollowRequestAccepted":
        return "Your follow request has been accepted";
      case "PropertyNewTripRequest":
        return "You have received a new trip request";
      case "PropertyNewMessageTrip":
        return "You have received a new message on a trip request";
      case "PropertyAddedAsHost":
        return "You have been added as an owner";
      case "PropertyGuestListAdd":
        return "You have been added to a guest list";
      case "PropertyHostGuestListAdd":
        return "A guest has been added to your guest list";
      default:
        return false;
    }
  }
  loadWS(token) {
    if (this.ws && this.ws.close) {
      this.ws.close();
    }
    if (!process.env.RAZZLE_APP_WS) {
      return console.warn(
        "Cannot start WebSocket because ENV var 'RAZZLE_APP_WS' is not set"
      );
    }

    if (!token) return false;

    this.ws = new ReconnectingWebSocket(
      process.env.RAZZLE_APP_WS + "?token=" + token
    );

    this.ws.addEventListener("message", (message) => {
      const data = JSON.parse(message.data);
      if (data.type === "notification") {
        const notification = this.api.transformNotification(message);
        const notificationMessage = this.getNotificationMessage(
          notification.type
        );
        if (notificationMessage) {
          this.dispatch(appSnackbarMessage({ message: notificationMessage }));
        }

        this.dispatch(fetchNotifications());
      }
      if (data.type === "feed") {
        const feed = this.api.transformNotification(message);
        this.dispatch(addFeedFromPushNotification(feed));
      }
    });
  }
}

export default OurWebSocket;
