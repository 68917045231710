import common from "@material-ui/core/colors/common";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core/styles";
const { white } = common;

const theme = (initialWidth) => {
  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    props: {
      MuiWithWidth: {
        // Initial width property
        initialWidth: initialWidth, // Breakpoint being globally set 🌎!
      },
    },
    palette: {
      primary: {
        main: "#463F56",
        selected: "#192959",
        dark: "#192959",
        vdark: "#1B2A56",
        link: "#445175",
        light: "#919298",
      },
      secondary: {
        white: "#fff",
        selected: "#192959",
        main: "#FFB41F",
        hover: "#0F0F10",
        border: "#D5D5D5",
        bg: "#F4F4F4",
        danger: "#CA372F",
      },
      default: {
        main: grey[500],
        dark: grey[700],
      },
      grey: {
        light: "#fefefe",
        medium: "#ddd",
        dark: "#555",
      },
      error: {
        light: red.A100,
        main: red.A400,
        dark: red[700],
        vdark: red[900],
      },
      success: {
        main: "#a9bfbf",
      },
      background: {
        default: white,
        dark: "#f8f8f8",
        vdark: "#373737",
      },
      text: {
        main: "#0F0F10",
        dark: "#182A56",
      },
    },
    typography: {
      fontFamily: "Poppins",
    },
    shadow: {
      light: "rgba(30,30,30,0.1) 0 0 10px 0",
      medium: "rgba(30,30,30,0.25) 0 0 12px 0",
      heavy: "rgba(30,30,30,0.4) 0 0 16px 0",
    },
    overrides: {
      MuiTypography: {
        subtitle1: {
          margin: 0,
          fontSize: 18,
          color: "#333",
          marginBottom: ".5rem",
				},
				subtitle2: {
          margin: 0,
          fontSize: 14,
          color: "#0f0f10",
					fontWeight: "bold",
					marginBlockStart: "1em",
					marginBlockEnd: "1em",
				},
        caption: {
          fontSize: 14,
          marginBottom: ".5rem",
					color: "#777",
				},
				body1: {
					fontSize: 14,
				}
      },
      // MuiOutlinedInput: {
      //   "& $notchedOutline": {
      //     border: "1px solid #D5D5D5"
      //   }
      // }
    },
  });
};

export default theme;
