import Resource from "./resource";

let resource = new Resource({
  singularName: "inbox",
  pluralName: "inboxes"
});

export function fetchInboxMessages() {
  return function(dispatch, getState, api) {
    dispatch(resource.fetchingPlural);
    return api
      .apiRequest(`stays`, "GET")
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export default resource.reducer;
