import models from "./models";
import axios from "axios";
import JsonApi from "devour-client";
import humps from "humps";
const qs = require("qs");

const apiUrl = process.env.RAZZLE_APP_API;
class ClientApi {
  constructor(auth) {
    auth.setApiAxiosRequest(this.apiAxiosRequest);

    this.jsonApi = new JsonApi({ apiUrl });
    this.axiosApi = axios.create({
      baseURL: apiUrl
    });
    this.auth = auth;
    this.setToken(auth.getToken());
    this.setupJsonAPi();
  }

  getToken() {
    return this.auth.getToken();
  }
  setToken(token) {
    this.token = token;
    this.jsonApi.headers["Authorization"] = "Bearer " + token;
  }

  setAxiosHeader() {
    // set the json-api (axios) token for future authenticated requests
    this.axiosApi.defaults.headers.common["Authorization"] =
      "Bearer " + this.token;
  }

  setupJsonAPi() {
    let errorsMiddleware = {
      name: "errors",
      error: function(payload) {
        // check for expired api requests
        console.error("payload:", payload);
        if (
          payload.response &&
          payload.response.status === 401 &&
          payload.response.statusText === "Unauthorized"
        ) {
          if (
            !this.token ||
            (this.token && !this.auth.isTokenExpired(this.token, true))
          ) {
            this.auth.unauthorized();
          }
        }

        // format errors
        let errors =
          payload.response &&
          payload.response.data &&
          payload.response.data.errors;

        return { errors };
      }
    };

    let paramsSerializerMiddleware = {
      name: "params-serializer",
      req: async payload => {
        if (payload.req.method === "GET") {
          payload.req.paramsSerializer = function(params) {
            return qs.stringify(params, {
              arrayFormat: "brackets",
              encode: true
            });
          };
        }

        return payload;
      }
    };

    /**
     * Transform incoming values to camelCase
     */
    let humperMiddlware = {
      name: "humper",
      res: payload => {
        return humps.camelizeKeys(payload);
      }
    };

    this.jsonApi.insertMiddlewareBefore(
      "axios-request",
      paramsSerializerMiddleware
    );
    this.jsonApi.insertMiddlewareAfter("response", humperMiddlware);
    this.jsonApi.replaceMiddleware("errors", errorsMiddleware);

    // model definitions
    this.jsonApi.define("address", models.address);
    this.jsonApi.define("booking", models.booking);
    this.jsonApi.define("follow", models.follow);
    this.jsonApi.define("group", models.group);
    this.jsonApi.define("image", models.image);
    this.jsonApi.define("invite", models.invite);
    this.jsonApi.define("location", models.location);
		this.jsonApi.define("message", models.message);
		this.jsonApi.define("guestMessage", models.guestMessage);
    this.jsonApi.define("property", models.property);
    this.jsonApi.define("trip", models.trip);
    this.jsonApi.define("user", models.user);
    this.jsonApi.define("notification", models.notification);
    this.jsonApi.define("setting", models.setting);
    this.jsonApi.define("amenity", models.amenity);
    this.jsonApi.define("propertiesamenity", models.propertiesAmenity);
    this.jsonApi.define("rate", models.rate);
    this.jsonApi.define("ratestrip", models.ratesTrip);
    this.jsonApi.define("pricing", models.pricing);
    this.jsonApi.define("card", models.card);
    this.jsonApi.define("connectaccount", models.connectAccount);
    this.jsonApi.define("paymenttransaction", models.paymentTransaction);
    this.jsonApi.define("profile", models.profile);
    this.jsonApi.define("feed", models.feed);
  }
  serializeResource(model, resource) {
    return this.jsonApi.serialize.resource.call(this.jsonApi, model, resource);
  }
  transformNotification(notification) {
    const data = JSON.parse(notification.data);
    let finalData = this.jsonApi.deserialize.resource.call(
      this.jsonApi,
      data.content.data,
      data.content.included
    );
    if (finalData.content && finalData.content.length > 0) {
      const content = JSON.parse(finalData.content);
      finalData.content = this.jsonApi.deserialize.resource.call(
        this.jsonApi,
        content.data,
        content.included
      );
    }

    return humps.camelizeKeys(finalData);
  }

  transformAxios(response) {
    const data = response.data;
    let finalData = this.jsonApi.deserialize.resource.call(
      this.jsonApi,
      data.data,
      data.included
    );

    return humps.camelizeKeys(finalData);
  }

  async createResource(type, resource) {
    return await new Promise((resolve, reject) => {
      this.jsonApi
        .create(type, resource)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
          if (response.errors) {
            reject(response.errors);
          }
        })
        .catch(response => {
          reject(response.errors);
        });
    });
  }

  async updateResource(type, resource, params = {}, meta = {}) {
    if (this.token) {
      this.jsonApi.headers["Authorization"] = "Bearer " + this.token;
    }
    return await new Promise((resolve, reject) => {
      this.jsonApi
        .update(type, resource, params, meta)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
          if (response.errors) {
            reject(response.errors);
          }
        })
        .catch(response => {
          reject(response.errors);
        });
    });
  }

  apiRequest(path, method, queryParams, resource) {
    if (this.token) {
      this.jsonApi.headers["Authorization"] = "Bearer " + this.token;
		}
		return new Promise((resolve, reject) => {
			this.jsonApi
				.request(`${apiUrl}/${path}`, method, queryParams, resource)
        .then(response => {
					resolve(response);
        })
        .catch(response => {
          reject(response);
        });
    });
  }

  async rpcRequest(method, params) {
    this.setAxiosHeader();
    return await this.axiosApi.post("/rpc", {
      method,
      params
    });
  }

  async apiAxiosRequest(
    path,
    method,
    data,
    params = {},
    withToken = false,
    transform = false
  ) {
    let config = {
      method,
      params,
      url: `${apiUrl}/${path}`,
      data
    };
    if (withToken) {
      if (this.token)
        config.headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token
        };
    }
    if (transform) {
      let response = await axios(config);
      return this.transformAxios(response);
    }
    return await axios(config);
  }
}

export default ClientApi;
