const models = {};

models.address = {
  address1: "",
  address2: "",
  city: "",
  region: "",
  "postal-code": "",
  "postal-code-suffix": "",
  country: "",
  status: "",
  "is-deleted": "",
  "created-at": "",
  "updated-at": "",
  location: {
    jsonApi: "hasOne",
    type: "locations",
  },
  meta: "",
};

models.follow = {
  property: {
    jsonApi: "hasOne",
    type: "properties",
  },
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
};

models.group = {
  name: "",
  property: {
    jsonApi: "hasOne",
    type: "properties",
  },
  "is-global": "",
  rates: {
    jsonApi: "hasMany",
    type: "rates",
  },
  "guests-count": "",
};

models.image = {
  filename: "",
  filepath: "",
  filesize: "",
  "mime-type": "",
  "sort-order": "",
  url: "",
  property: {
    jsonApi: "hasOne",
    type: "properties",
  },
  "created-at": "",
  "updated-at": "",
};

models.invite = {
  code: "",
  email: "",
  "invite-code": "",
  message: "",
  property: {
    jsonApi: "hasOne",
    type: "properties",
  },
  fromUser: {
    jsonApi: "hasOne",
    type: "users",
  },
  toUser: {
    jsonApi: "hasOne",
    type: "users",
  },
  "sent-at": "",
  "created-at": "",
};

models.location = {
  "place-id": "",
  label: "",
  latitude: "",
  longitude: "",
  "formatted-address": "",
  "location-type": "",
  "establishment-name": "",
  "point-of-interest": "",
  "street-number": "",
  route: "",
  premise: "",
  subpremise: "",
  neighborhood: "",
  "colloquial-area": "",
  "natural-feature": "",
  locality: "",
  sublocality: "",
  "sublocality-level1": "",
  "sublocality-level2": "",
  "sublocality-level3": "",
  "administrative-area-level1": "",
  "administrative-area-level2": "",
  "administrative-area-level3": "",
  "postal-code": "",
  "postal-code-suffix": "",
  country: "",
  "created-at": "",
  "updated-at": "",
};

models.message = {
  content: "",
  trip: {
    jsonApi: "hasOne",
    type: "trips",
  },
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  "created-at": "",
  "updated-at": "",
};

models.property = {
  image: {
    jsonApi: "hasOne",
    type: "images",
  },
  active: "",
  privacy: "",
  "has-access": "",
  "has-requested": "",
  "is-friend": "",
  "is-host": "",
  name: "",
  slug: "",
  "description-raw": "",
  "description-html": "",
  "rules-raw": "",
  "rules-html": "",
  "instructions-raw": "",
  "instructions-html": "",
  "upcoming-trips": "",
  "previous-trips": "",
  "created-at": "",
  "updated-at": "",
  "facebook-url": "",
  "instagram-url": "",
  "twitter-url": "",
  "airbnb-url": "",
  "bookingcom-url": "",
  "vrbo-url": "",
  "extra-url": "",
  address: {
    jsonApi: "hasOne",
    type: "addresses",
  },
  images: {
    jsonApi: "hasMany",
    type: "images",
  },
  guests: {
    jsonApi: "hasMany",
    type: "users",
  },
  hosts: {
    jsonApi: "hasMany",
    type: "users",
  },
  friends: {
    jsonApi: "hasMany",
    type: "users",
  },
  amenities: {
    jsonApi: "hasMany",
    type: "propertiesamenities",
  },
  card: {
    jsonApi: "hasOne",
    type: "cards",
  },
  "connect-account": {
    jsonApi: "hasOne",
    type: "connect",
  },
  sleeps: "",
  bathrooms: "",
  bedrooms: "",
};

models.trip = {
  adults: "",
  children: "",
  "start-date": "",
  "end-date": "",
  status: "",
  property: {
    jsonApi: "hasOne",
    type: "properties",
  },
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  messages: {
    jsonApi: "hasMany",
    type: "messages",
  },
  "last-message": {
    jsonApi: "hasOne",
    type: "messages",
  },
  pricing: {
    jsonApi: "hasOne",
    type: "pricings",
  },
  isHost: "",

  message: "",
  "instructions-at": "",
  "created-at": "",
  "updated-at": "",
};

models.user = {
  active: "",
  name: "",
  "first-name": "",
  "middle-name": "",
  "last-name": "",
  email: "",
  picture: "",
  "fb-link": "",
  "group-id": "",
  "created-at": "",
  "updated-at": "",
  "is-friend": "",
  username: "",
  "facebook-url": "",
  "instagram-url": "",
  "linkedin-url": "",
  "twitter-url": "",
};

models.booking = {
  "start-date": "",
  "end-date": "",
  property: {
    jsonApi: "hasOne",
    type: "properties",
  },
  trip: {
    jsonApi: "hasOne",
    type: "trips",
  },
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  "created-at": "",
  "updated-at": "",
};

models.notification = {
  content: "",
  viewed: "",
  type: "",
  property: {
    jsonApi: "hasOne",
    type: "properties",
  },
  trip: {
    jsonApi: "hasOne",
    type: "trips",
  },
  group: {
    jsonApi: "hasOne",
    type: "groups",
  },
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  "created-at": "",
};

models.amenity = {
  name: "",
  value: "",
  "value-bool": "",
  "is-boolean": "",
  "is-note": "",
  "is-quantity": "",
};

models.propertiesAmenity = {
  name: "",
  value: "",
  "property-id": "",
  "amenity-id": "",
  amenity: {
    jsonApi: "hasOne",
    type: "amenities",
  },
  property: {
    jsonApi: "hasOne",
    type: "properties",
  },
  note: "",
  quantity: "",
  "created-at": "",
};

// models.propertiesAmenit = {
//   name: "",
//   value: "",
//   "value-bool": "",
//   "is-boolean": "",
//   "is-note": "",
//   "is-quantity": ""
// };

models.rate = {
  name: "",
  type: "",
  price: "",
  "start-date": "",
  "end-date": "",
  group: {
    jsonApi: "hasOne",
    type: "groups",
  },
  property: {
    jsonApi: "hasOne",
    type: "properties",
  },
  "created-at": "",
};

models.ratesTrip = {
  name: "",
  type: "",
  price: "",
  "calculated-price": "",
  "start-date": "",
  "end-date": "",
  "removed-at": "",
  group: {
    jsonApi: "hasOne",
    type: "groups",
  },
  trip: {
    jsonApi: "hasOne",
    type: "trips",
  },
  property: {
    jsonApi: "hasOne",
    type: "properties",
  },
  "created-at": "",
};

models.pricing = {
  total: "",
  "final-total": "",
  "calculated-price": "",
  trip: {
    jsonApi: "hasOne",
    type: "trips",
  },
  ratestrip: {
    jsonApi: "hasMany",
    type: "ratestrips",
  },
};

models.setting = {
  name: "",
  value: "",
  "value-bool": "",
  "is-boolean": "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
};

models.card = {
  last4: "",
  brand: "",
  "exp-month": "",
  "exp-year": "",
  "card-id": "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  "created-at": "",
};

models.connectAccount = {
  "bank-name": "",
  "routing-number": "",
  type: "",
  last4: "",
  brand: "",
  "exp-month": "",
  "exp-year": "",
  "account-id": "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  "created-at": "",
};

models.paymentTransaction = {
  "intent-id": "",
  "intent-secret-id": "",
  status: "",
  price: "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  card: {
    jsonApi: "hasOne",
    type: "cards",
  },
  "connect-account": {
    jsonApi: "hasOne",
    type: "connectaccounts",
  },
  trip: {
    jsonApi: "hasOne",
    type: "trips",
  },
  "created-at": "",
};

models.profile = {
  active: "",
  name: "",
  "first-name": "",
  "last-name": "",
  email: "",
  picture: "",
  "has-facebook": "",
  "group-id": "",
  "created-at": "",
  "updated-at": "",
  role: "",
  "properties-followed": "",
  username: "",
  "facebook-url": "",
  "instagram-url": "",
  "linkedin-url": "",
  "twitter-url": "",
  properties: {
    jsonApi: "hasMany",
    type: "properties",
  },
};

models.feed = {
  type: "",
  property: {
    jsonApi: "hasOne",
    type: "properties",
  },
  trip: {
    jsonApi: "hasOne",
    type: "trips",
  },
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  "created-at": "",
};

models.guestMessage = {
	property: {
		jsonApi: "hasOne",
		type: "properties"
	},
	fromUser: {
		jsonApi: "hasOne",
		type: "users"
	},
	toUser: {
		jsonApi: "hasOne",
		type: "users"
	},
	email: "",
	message: "",
	subject: "",
	"sent-at": "",
  "created-at": "",
  resend: "",
}

export default models;
