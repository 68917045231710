import Resource from "./resource";

let resource = new Resource({
  singularName: "message",
  pluralName: "messages"
});

export function addMessage(values) {
  return async function(dispatch, getState, api) {
    dispatch(resource.addingSingular());
    const ids = getState().messages.ids;
    try {
      const message = await api.createResource("message", values);
      if (message) {
        ids.unshift(message.id);
        dispatch(resource.successAddingSingular(ids, message));
      }
      return message;
    } catch (error) {
      dispatch(resource.errorAddingSingular(error));
      throw error;
    }
  };
}

export default resource.reducer;
