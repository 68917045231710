import { getErrorMessage } from "../../helpers/utils";
import Resource from "./resource";
import axios from "axios";

let resource = new Resource({
  singularName: "image",
  pluralName: "images"
});

export function fetchImagesByProperty(propertyId) {
  return function(dispatch, getState, api) {
    dispatch(resource.fetchingPlural);
    return api
      .apiRequest(`images`, "GET", { property_id: propertyId })
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export function fetchImage(id) {
  return function(dispatch, getState, api) {
    dispatch(resource.fetchingSingular);
    api
      .apiRequest(`images/${id}`, "GET")
      .then(response => {
        dispatch(resource.successSingular([response.data.id], response.data));
      })
      .catch(error => {
        console.error(error);
        dispatch(resource.errorSingular(error));
      });
  };
}

export function addImage(values) {
  return async function(dispatch, getState, api) {
    dispatch(resource.addingSingular());
    const ids = getState().images.ids;
    try {
      const image = await api.createResource("images", values);
      if (image) {
        ids.unshift(image.id);
        dispatch(resource.successAddingSingular(ids, image));
      }
      return image;
    } catch (error) {
      dispatch(resource.errorAddingSingular(error));
      throw error;
    }
  };
}

export function updateImage(values) {
  return function(dispatch, getState, api) {
    dispatch(resource.updatingSingular());
    const image = api
      .updateResource("image", values)
      .then(response => {
        dispatch(resource.successUpdatingSingular(response));
        return response;
      })
      .catch(error => {
        dispatch(resource.errorUpdatingSingular(error));
        throw error;
      });
    return image;
  };
}

export function deleteImage(id) {
  return async function(dispatch, getState, api) {
    dispatch(resource.deletingSingular());
    try {
      await api.apiRequest(`images/${id}`, "DELETE").then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorDeletingSingular(error));
          throw error;
        } else {
          dispatch(removeImage(id));
        }
      });
    } catch (error) {
      dispatch(resource.errorDeletingSingular(error));
      throw error;
    }
  };
}

export function removeImage(id) {
  return function(dispatch, getState, api) {
    const ids = getState().images.ids;
    const i = ids.indexOf(id.toString());
    if (i !== -1) {
      ids.splice(i, 1);
    }
    dispatch(resource.successDeletingSingular(ids));
  };
}

export function uploadImages(files, params = {}) {
  return async function(dispatch, getState, api) {
    dispatch(resource.uploadingSingular());
    let uploads = [];
    // build uploads first
    for (let i = 0; i < files.length; i++) {
      // add to uploads
      let file = files[i];
      let filename = file.name;
      uploads[i] = { id: i, filename, progress: 0, error: null };
    }
    for (let i = 0; i < files.length; i++) {
      try {
        let file = files[i];
        let filename = file.name;
        // set index to id
        file.id = i;
        // set the file params
        let fparams = params;
        fparams.filename = filename;
        let resp = await uploadRequest(
          `images`,
          "POST",
          file,
          fparams,
          dispatch,
          uploads,
          api.getToken()
        );
        if (resp.status !== 200) {
          let errorMessage = resp.data.errors[0].title;
          uploads[i].error = errorMessage;
          dispatch(resource.uploadingProgress(uploads));
        }
      } catch (error) {
        console.error("error:", error);
        dispatch(resource.errorUploadingSingular(error));
      }
    }
    dispatch(resource.successUploadingSingular());
  };
}

export function sortImages(images) {
  return async function(dispatch, getState, api) {
    dispatch(resource.updatingPlural());
    try {
      await api
        .rpcRequest("sort-images", {
          images: images.map(i => ({
            id: i.id,
            sort_order: i.sortOrder
          }))
        })
        .then(response => {
          if (response.errors) {
            let error = getErrorMessage(response);
            dispatch(resource.errorUpdatingPlural(error));
            throw error;
          }
        });
    } catch (error) {
      dispatch(resource.errorUpdatingPlural(error));
      throw error;
    }
  };
}

export async function uploadRequest(
  path,
  method,
  data,
  params = {},
  dispatch,
  uploads,
  token
) {
  let progress = 0;
  return await axios({
    headers: { Authorization: "Bearer " + token },
    method,
    params,
    onUploadProgress: function(progressEvent) {
      progress = Math.floor(
        Number(Number(progressEvent.loaded) / Number(data.size)) * 100
      );
      uploads[data.id].progress = progress;
      dispatch(resource.uploadingProgress(uploads));
    },
    url: `${process.env.RAZZLE_APP_API}/${path}`,
    data
  });
}

export default resource.reducer;
