import { getErrorMessage } from "../../helpers/utils";
import Resource from "./resource";

let resource = new Resource({
  singularName: "setting",
  pluralName: "settings"
});

export function settingUpdate(settingName, value) {
  return async function(dispatch, getState, api) {
    dispatch(resource.updatingSingular());
    try {
      let data = {
        setting_name: settingName,
        value: value
      };
      await api.rpcRequest("setting-update", data).then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorUpdatingSingular(error));
          throw error;
        }
        // no update for store on rpc request
        //dispatch(resource.successUpdatingSingular());
        return response;
      });
      dispatch(fetchSettings());
    } catch (error) {
      dispatch(resource.errorUpdatingSingular(error));
      throw error;
    }
  };
}

// export function fetchUserUpdate(id, params = {}) {
//   return function(dispatch, getState, api) {
//     dispatch(resource.updatingSingular);
//     api.apiRequest(`users/${id}`, "GET", params)
//       .then(response => {
//         dispatch(resource.successUpdatingSingular(response.data));
//       })
//       .catch(error => {
//         console.error(error);
//         dispatch(resource.errorUpdatingSingular(error));
//       });
//   };
// }

// export function fetchUser(id, params = {}) {
//   return function(dispatch, getState, api) {
//     dispatch(resource.fetchingSingular);
//     api.apiRequest(`users/${id}`, "GET", params)
//       .then(response => {
//         dispatch(
//           resource.successSingularMeta([response.data.id], response.data)
//         );
//       })
//       .catch(error => {
//         console.error(error);
//         dispatch(resource.errorSingular(error));
//       });
//   };
// }

export function fetchSettings(params = {}) {
  return function(dispatch, getState, api) {
    dispatch(resource.fetchingPlural);
    const users = api
      .apiRequest(`users/settings`, "GET", params)
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
    return users;
  };
}
export default resource.reducer;
