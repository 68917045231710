import React from "react";

import { asyncComponent } from "@jaredpalmer/after";
import asyncComponentLogged from "./helpers/asyncComponentLogged";
import NoMatch from "./components/404/NoMatch";
import redirectRouteComponent from "./helpers/redirectComponent";
function loadRoutes(auth) {
  return [
    {
      path: "/",
      exact: true,
      component: asyncComponent({
        loader: () =>
          auth.isAuthenticated()
            ? import("./containers/Dashboard/DashboardContainer")
            : import("./components/Home/Home"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },

    {
      path: "/about",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/About/About"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/covid-19",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/Covid19/Covid19"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/pricing",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/Pricing/Pricing"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/login",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/Login/Login"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/forgot",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/Auth/ForgotPassword/ForgotPassword"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/signup",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/SignUp/SignUp"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/learn",
      exact: true,
      component: redirectRouteComponent({
        redirectTo: "/features",
        statusCode: 301,
      }),
    },
    {
      path: "/airbnb-alternative",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/AirbnbAlt/AirbnbAlt"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/features",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/Features/Features"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/confirm-email",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/ConfirmEmail/ConfirmEmail"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/confirm-email/:code",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/ConfirmEmail/ConfirmEmail"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/invite/:code",
      exact: true,
      component: asyncComponent({
        loader: () => import("./containers/Invite/InviteContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/privacy-policy",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/Privacy/Privacy"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/reset/:resetCode",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/Auth/ResetPassword/ResetPassword"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/terms-of-service",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/Terms/Terms"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/bor",
      exact: true,
      component: asyncComponent({
        loader: () => import("./components/BillOfRights/BillOfRights"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },

    {
      path: "/u/:username",
      exact: true,
      component: asyncComponent({
        loader: () => import("./containers/Users/User"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/p/:slug",
      exact: true,
      component: asyncComponent({
        loader: () => import("./containers/Properties/ViewPropertyContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/search",
      exact: true,
      component: asyncComponent({
        loader: () => import("./containers/Search/Search"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    ...loggedRoutes(auth),
    ...adminRoutes(auth),
    {
      //404
      component: NoMatch,
    },
  ];
}

function adminRoutes(auth) {
  return [
    {
      path: "/admin/users",
      exact: true,
      component: asyncComponentLogged({
        loader: () => import("./containers/Admin/AdminUsersContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
  ];
}
function loggedRoutes(auth) {
  return [
    {
      path: "/dashboard",
      exact: true,
      component: asyncComponentLogged({
        loader: () => import("./containers/Dashboard/DashboardContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/dashboard/new-properties",
      exact: true,
      component: asyncComponentLogged({
        loader: () =>
          import("./containers/Dashboard/DashboardNewPropertiesContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/invite-guests/:propertyId?",
      exact: true,
      component: asyncComponentLogged({
        loader: () => import("./containers/InviteWizard/InviteWizardContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/message-guests/:propertyId?",
      exact: true,
      component: asyncComponentLogged({
        loader: () =>
          import("./containers/MessageWizard/MessageWizardContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/add-property/:propertyId",
      exact: true,
      component: asyncComponentLogged({
        loader: () => import("./containers/Properties/AddPropertyContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/add-property",
      exact: true,
      component: asyncComponentLogged({
        loader: () => import("./containers/Properties/AddPropertyContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/staysandrequests",
      exact: true,
      component: asyncComponentLogged({
        loader: () => import("./containers/Inbox/StaysAndRequestsContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/inbox",
      exact: true,
      component: asyncComponentLogged({
        loader: () => import("./containers/Inbox/StaysAndRequestsContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/inbox/:stayId",
      exact: true,
      component: asyncComponentLogged({
        loader: () => import("./containers/Inbox/InboxDetailContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },

    {
      path: "/manage",
      component: asyncComponentLogged({
        loader: () => import("./containers/Manage/ManageContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/settings",
      component: asyncComponentLogged({
        loader: () => import("./containers/Settings/SettingsContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/Account",
      component: asyncComponentLogged({
        loader: () => import("./containers/Account/AccountContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
    },
    {
      path: "/stripe/connect",
      component: asyncComponentLogged({
        loader: () =>
          import("./containers/Stripe/StripeConnectHandlerContainer"), // required
        Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
      }),
		},
		{
			path: "/marketing",
			exact: true,
			component: asyncComponentLogged({
				loader: () => import("./containers/Marketing/MarketingContainer"), // required
				Placeholder: () => <div>...LOADING...</div> //this is optional, just returns null by default
			}),
		},
  ];
}
export default loadRoutes;
