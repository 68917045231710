import { getErrorMessage } from "../../helpers/utils";
import Resource from "./resource";

let resource = new Resource({
  singularName: "invite",
  pluralName: "invites"
});

export function sendInvites(emails, message, propertyId) {
  return async function(dispatch, getState, api) {
    dispatch(resource.fetchingSingular);
    try {
      let data = {
        invites: emails.map(e => ({ email: e })),
        message: message,
        resend: true
      };
      if (propertyId) data.property_id = propertyId;
      await api.rpcRequest("send-invites", data).then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorUpdatingSingular(error));
          throw error;
        }
        dispatch(resource.successUpdatingPlural());
      });
    } catch (error) {
      dispatch(resource.errorSingular(error));
      throw error;
    }
  };
}

export function fetchInviteByCode(code) {
  return async function(dispatch, getState, api) {
    dispatch(resource.fetchingSingular);
    try {
      return await api
        .apiRequest(`invites/code/${code}`, "GET")
        .then(response => {
          dispatch(resource.successSingular([response.data.id], response.data));
          return response.data;
        });
    } catch (error) {
      dispatch(resource.errorSingular(error));
      throw error;
    }
  };
}

export function claimInvite(code) {
  return async function(dispatch, getState, api) {
    dispatch(resource.updatingPlural());
    try {
      return await api
        .rpcRequest("claim-invite", {
          code
        })
        .then(response => {
          if (response.errors) {
            let error = getErrorMessage(response);
            dispatch(resource.errorUpdatingSingular(error));
            throw error;
          }
          dispatch(resource.successUpdatingPlural());
          return true;
        });
    } catch (error) {
      dispatch(resource.errorUpdatingPlural(error));
      throw error;
    }
  };
}

export default resource.reducer;
