import SnackbarMessage from "../../components/Message/SnackbarMessage";
import * as appActionCreators from "../../redux/modules/app";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Snackbar extends Component {
  closeSnackbarMessage = () => {
    this.props.appSnackbarClose();
  };

  render() {
    const { snackbar } = this.props;
    if (!snackbar) return <></>;
    return (
      <SnackbarMessage
        allowUpdates={true}
        message={snackbar.message}
        onClose={this.closeSnackbarMessage}
        type={snackbar.type}
      />
    );
  }
}

function mapStateToProps({ app }, props) {
  return {
    snackbar: app.snackbar
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...appActionCreators
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
