import * as React from "react";

/**
 * Returns a new React component, ready to be instantiated.
 * Note the closure here protecting Component, and providing a unique
 * instance of Component to the static implementation of `load`.
 */
export default function redirectRouteComponent(props) {
  return class RedirectRouteComponent extends React.Component {
    static getInitialProps(ctx) {
      return Promise.resolve(props);
    }

    render() {
      return null;
    }
  };
}
