function writeCookie(name, value, days) {
  var date, expires;
  if (days) {
    date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

function deleteCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}

function readCookie(name) {
  var i,
    c,
    ca,
    nameEQ = name + "=";
  ca = document.cookie.split(";");
  for (i = 0; i < ca.length; i++) {
    c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return false;
}

const isClient = typeof window !== "undefined";

class OwnCookies {
  setServerCookies(cookies, setCookies) {
    this.serverCookies = cookies;
    this.serverCookiesSet = setCookies;
  }

  set(name, value, days = 200) {
    if (isClient) {
      return writeCookie(name, value, days);
    }
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    this.serverCookiesSet(name, value, { expires: date });
  }

  get(name) {
    if (isClient) {
      return readCookie(name);
    }

    return this.serverCookies && this.serverCookies[name];
  }
  delete(name) {
    if (isClient) {
      return deleteCookie(name);
    }

    // return this.serverCookies && this.serverCookies.set(name);
  }
}

export default OwnCookies;
