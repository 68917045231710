import Resource from "./resource";

let resource = new Resource({
  singularName: "datepicker",
  pluralName: "datepickers"
});

export function fetchDates(propertyId) {
  return function(dispatch, getState, api) {
    dispatch(resource.fetchingPlural);
    return api
      .apiRequest(`dates/${propertyId}`, "GET")
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export default resource.reducer;
