// @flow weak

import PropTypes from "prop-types";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  layout: {
    flexGrow: 1,
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: "auto",
    minHeight: "100vh",
    backgroundColor: "#F4F4F4",
  },
  landing: {
    flexGrow: 1,
    // header height
    marginTop: 80,
    width: "auto",
  },
  property: {
    flexGrow: 1,
    backgroundColor: "#F4F4F4",
  },
  [theme.breakpoints.down("sm")]: {
    landing: {
      marginTop: 65,
    },
  },
}));

function AppContent(props, context) {
  const { children, wrapperStyle } = props;
  const classes = useStyles();
  let wrapperClass = classes.landing;
  if (wrapperStyle === "layout") {
    wrapperClass = classes.layout;
  }
  if (wrapperStyle === "property") {
    wrapperClass = classes.property;
  }
  return <main className={wrapperClass}>{children}</main>;
}

AppContent.propTypes = {
  children: PropTypes.node,
};

export default AppContent;
