import React from "react";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";

import common from "@material-ui/core/colors/common";
import { withStyles } from "@material-ui/core/styles";
const { white } = common;

export const styles = theme => ({
  menuButton: {
    display: "none",
    borderRadius: "0px",
    backgroundColor: theme.palette.primary.main,
    color: white,
    width: 64,
    height: 64,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  [theme.breakpoints.down("sm")]: {
    menuButton: {
      display: "block !important"
    }
  }
});

const AppMenuButton = props => {
  const { classes, handleToggle, open } = props;
  const icon = open ? <CloseIcon /> : <MenuIcon />;
  return (
    <IconButton
      className={classes.menuButton}
      onClick={() => handleToggle()}
      disableFocusRipple
      disableRipple
    >
      {icon}
    </IconButton>
  );
};

export default withStyles(styles, { name: "AppMenuButton" })(AppMenuButton);
