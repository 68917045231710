// @flow weak

import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";

import AppNavItem from "./AppNavItem";
import { withStyles } from "@material-ui/core/styles";

export const styles = theme => ({
  navMenu: {
    flex: 1,
    display: "flex",
    marginLeft: theme.spacing(2),
    justifyContent: "flex-end",
    marginRight: 40
  },
  navLink: {
    color: "#555",
    display: "inline-flex",
    alignContent: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3.5),
    textDecoration: "none",
    borderBottom: "2px solid transparent",
    transition: theme.transitions.create("all"),
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
      borderBottomColor: theme.palette.secondary.main
    }
  },
  navSpace: {
    width: 40
  },
  [theme.breakpoints.down("sm")]: {
    landingMenu: {
      display: "none"
    },
    navMenu: {
      display: "none"
    }
  },
  [theme.breakpoints.down("xs")]: {
    navMenu: {
      display: "none"
    }
  }
});

class AppNav extends Component {
  static propTypes = {
    children: PropTypes.node,
    currentPath: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string,
    isAuthenticated: PropTypes.bool
  };
  render() {
    const { classes, currentPath, isAuthenticated } = this.props;
    if (isAuthenticated) {
      return (
        <nav className={classes.navMenu}>
          <div className={classes.navSpace}>&nbsp;</div>
          <AppNavItem title="Places" to="/" aliasPath="/p/" currentPath={currentPath} />
          <div className={classes.navSpace}>&nbsp;</div>
					<AppNavItem title="Stays" to="/inbox" currentPath={currentPath} />
					<div className={classes.navSpace}>&nbsp;</div>
					<AppNavItem title="Marketing" to="/marketing" currentPath={currentPath} />
          <div className={classes.navSpace}>&nbsp;</div>
          <AppNavItem title="Manage" to="/manage" currentPath={currentPath} />
        </nav>
      );
    }

    return <nav className={classNames(classes.navMenu, classes.landingMenu)} />;
  }
}

export default withStyles(styles, { name: "AppNav" })(AppNav);
