export const countries = [
  {
    name: "United States",
    iso2: "US",
    dialCode: 1,
    format: "(###) ###-####",
    position: 69
  },
  {
    name: "Afghanistan",
    iso2: "AF",
    dialCode: 93,
    format: "+93-##-###-####",
    position: 3180
  },
  {
    name: "Albania",
    iso2: "AL",
    dialCode: 355,
    format: "+355(###)###-###",
    position: 1310
  },
  {
    name: "Algeria",
    iso2: "DZ",
    dialCode: 213,
    format: "+213-##-###-####",
    position: 681
  },
  {
    name: "American Samoa",
    iso2: "AS",
    dialCode: 1684,
    format: "+1(684)###-####",
    position: 2058
  },
  {
    name: "Andorra",
    iso2: "AD",
    dialCode: 376,
    format: "+376-###-###",
    position: 766
  },
  {
    name: "Angola",
    iso2: "AO",
    dialCode: 244,
    format: "+244(###)###-###",
    position: 2636
  },
  {
    name: "Anguilla",
    iso2: "AI",
    dialCode: 1264,
    format: "+1(264)###-####",
    position: 2687
  },
  {
    name: "Antigua and Barbuda",
    iso2: "AG",
    dialCode: 1268,
    format: "+1(268)###-####",
    position: 1140
  },
  {
    name: "Argentina",
    iso2: "AR",
    dialCode: 54,
    format: "+54(###)###-####",
    position: 3282
  },
  {
    name: "Armenia",
    iso2: "AM",
    dialCode: 375,
    format: "+374-##-###-###",
    position: 205
  },
  {
    name: "Aruba",
    iso2: "AW",
    dialCode: 297,
    format: "+297-###-####",
    position: 1021
  },
  {
    name: "Ascension Island",
    iso2: "AC",
    dialCode: 247,
    format: "+247 ############",
    position: 86
  },
  {
    name: "Australia",
    iso2: "AU",
    dialCode: 61,
    format: "+61 ### ### ###",
    position: 2279
  },
  {
    name: "Austria",
    iso2: "AT",
    dialCode: 43,
    format: "+43(###)###-####",
    position: 1735
  },
  {
    name: "Azerbaijan",
    iso2: "AZ",
    dialCode: 994,
    format: "+994-##-####-##-##",
    position: 1599
  },
  {
    name: "Bahamas",
    iso2: "BS",
    dialCode: 1242,
    format: "+1(242)###-####",
    position: 460
  },
  {
    name: "Bahrain",
    iso2: "BH",
    dialCode: 973,
    format: "+973-####-####",
    position: 1956
  },
  {
    name: "Bangladesh",
    iso2: "BD",
    dialCode: 880,
    format: "+880-##-###-###",
    position: 2364
  },
  {
    name: "Barbados",
    iso2: "BB",
    dialCode: 1246,
    format: "+1(246)###-####",
    position: 2075
  },
  {
    name: "Belarus",
    iso2: "BY",
    dialCode: 375,
    format: "+375(##)###-##-##",
    position: 1412
  },
  {
    name: "Belgium",
    iso2: "BE",
    dialCode: 32,
    format: "+32 ### ## ## ##",
    position: 1
  },
  {
    name: "Belize",
    iso2: "BZ",
    dialCode: 501,
    format: "+501-###-####",
    position: 613
  },
  {
    name: "Benin",
    iso2: "BJ",
    dialCode: 229,
    format: "+229-##-##-####",
    position: 1684
  },
  {
    name: "Bermuda",
    iso2: "BM",
    dialCode: 1441,
    format: "+1(441)###-####",
    position: 2585
  },
  {
    name: "Bhutan",
    iso2: "BT",
    dialCode: 975,
    format: "+975-#-###-###",
    position: 2483
  },
  {
    name: "Bolivia",
    iso2: "BO",
    dialCode: 591,
    format: "+591-#-###-###",
    position: 2177
  },
  {
    name: "Bosnia and Herzegovina",
    iso2: "BA",
    dialCode: 387,
    format: "+387-##-####",
    position: 2092
  },
  {
    name: "Botswana",
    iso2: "BW",
    dialCode: 267,
    format: "+267-##-###-###",
    position: 3724
  },
  {
    name: "Brazil",
    iso2: "BR",
    dialCode: 55,
    format: "+55-##-####-####",
    position: 1004
  },
  {
    name: "British Indian Ocean Territory",
    iso2: "IO",
    dialCode: 246,
    format: "+246-###-####",
    position: 86
  },
  {
    name: "British Virgin Islands",
    iso2: "VG",
    dialCode: 1284,
    format: "+1(284)###-####",
    position: 1854
  },
  {
    name: "Brunei",
    iso2: "BN",
    dialCode: 673,
    format: "+673-###-####",
    position: 2228
  },
  {
    name: "Burkina Faso",
    iso2: "BF",
    dialCode: 226,
    format: "+226-##-##-####",
    position: 953
  },
  {
    name: "Burundi",
    iso2: "BI",
    dialCode: 257,
    format: "+257-##-##-####",
    position: 2551
  },
  {
    name: "Cambodia",
    iso2: "KH",
    dialCode: 855,
    format: "+855-##-###-###",
    position: 290
  },
  {
    name: "Cameroon",
    iso2: "CM",
    dialCode: 237,
    format: "+237-####-####",
    position: 2806
  },
  {
    name: "Canada",
    iso2: "CA",
    dialCode: 1,
    format: "+1 (###) ###-####",
    position: 1803
  },
  {
    name: "Cape Verde",
    iso2: "CV",
    dialCode: 238,
    format: "+238(###)##-##",
    position: 3639
  },
  {
    name: "Caribbean Netherlands",
    iso2: "BQ",
    dialCode: 599,
    format: "+599-###-####",
    position: 3741
  },
  {
    name: "Cayman Islands",
    iso2: "KY",
    dialCode: 1345,
    format: "+1(345)###-####",
    position: 375
  },
  {
    name: "Central African Republic",
    iso2: "CF",
    dialCode: 236,
    format: "+236-##-##-####",
    position: 2466
  },
  {
    name: "Chad",
    iso2: "TD",
    dialCode: 235,
    format: "+235-##-##-##-##",
    position: 1055
  },
  {
    name: "Chile",
    iso2: "CL",
    dialCode: 56,
    format: "+56-#-####-####",
    position: 1752
  },
  {
    name: "China",
    iso2: "CN",
    dialCode: 86,
    format: "+86 ##-########",
    position: 1072
  },
  {
    name: "Colombia",
    iso2: "CO",
    dialCode: 57,
    format: "+57(###)###-####",
    position: 409
  },
  {
    name: "Comoros",
    iso2: "KM",
    dialCode: 269,
    format: "+269-##-#####",
    position: 1871
  },
  {
    name: "Congo - Brazzaville",
    iso2: "CG",
    dialCode: 242,
    format: "+242-##-###-####",
    position: 2398
  },
  {
    name: "Congo - Kinshasa",
    iso2: "CD",
    dialCode: 243,
    format: "+243(###)###-###",
    position: 1990
  },
  {
    name: "Cook Islands",
    iso2: "CK",
    dialCode: 682,
    format: "+682-##-###",
    position: 3112
  },
  {
    name: "Costa Rica",
    iso2: "CR",
    dialCode: 506,
    format: "+506 ####-####",
    position: 2857
  },
  {
    name: "Côte d’Ivoire",
    iso2: "CI",
    dialCode: 225,
    format: "+225-##-###-###",
    position: 2194
  },
  {
    name: "Croatia",
    iso2: "HR",
    dialCode: 385,
    format: "+385-##-###-###",
    position: 1174
  },
  {
    name: "Cuba",
    iso2: "CU",
    dialCode: 53,
    format: "+53-#-###-####",
    position: 987
  },
  {
    name: "Curaçao",
    iso2: "CW",
    dialCode: 599,
    format: "+599-###-####",
    position: 3758
  },
  {
    name: "Cyprus",
    iso2: "CY",
    dialCode: 357,
    format: "+357-##-###-###",
    position: 732
  },
  {
    name: "Czechia",
    iso2: "CZ",
    dialCode: 420,
    format: "+420(###)###-###",
    position: 3095
  },
  {
    name: "Denmark",
    iso2: "DK",
    dialCode: 45,
    format: "+45 ## ## ## ##",
    position: 1820
  },
  {
    name: "Djibouti",
    iso2: "DJ",
    dialCode: 253,
    format: "+253-##-##-##-##",
    position: 2874
  },
  {
    name: "Dominica",
    iso2: "DM",
    dialCode: 1767,
    format: "+1(767)###-####",
    position: 3350
  },
  {
    name: "Dominican Republic",
    iso2: "DO",
    dialCode: 1,
    format: "+1(###)###-####",
    position: 2007
  },
  {
    name: "Ecuador",
    iso2: "EC",
    dialCode: 593,
    format: "+593-#-###-####",
    position: 1531
  },
  {
    name: "Egypt",
    iso2: "EG",
    dialCode: 20,
    format: "+20(###)###-####",
    position: 3027
  },
  {
    name: "El Salvador",
    iso2: "SV",
    dialCode: 503,
    format: "+503 ####-####",
    position: 2160
  },
  {
    name: "Equatorial Guinea",
    iso2: "GQ",
    dialCode: 240,
    format: "+240-##-###-####",
    position: 1973
  },
  {
    name: "Eritrea",
    iso2: "ER",
    dialCode: 291,
    format: "+291-#-###-###",
    position: 936
  },
  {
    name: "Estonia",
    iso2: "EE",
    dialCode: 372,
    format: "+372-###-####",
    position: 3333
  },
  {
    name: "Ethiopia",
    iso2: "ET",
    dialCode: 251,
    format: "+251-##-###-####",
    position: 3367
  },
  {
    name: "Falkland Islands (Islas Malvinas)",
    iso2: "FK",
    dialCode: 500,
    format: "+500-#####",
    position: 3809
  },
  {
    name: "Faroe Islands",
    iso2: "FO",
    dialCode: 298,
    format: "+298-###-###",
    position: 1429
  },
  {
    name: "Fiji",
    iso2: "FJ",
    dialCode: 679,
    format: "+679-##-#####",
    position: 2500
  },
  {
    name: "Finland",
    iso2: "FI",
    dialCode: 358,
    format: "+358 ## ### ## ##",
    position: 2568
  },
  {
    name: "France",
    iso2: "FR",
    dialCode: 33,
    format: "+33 # ## ## ## ##",
    position: 324
  },
  {
    name: "French Guiana",
    iso2: "GF",
    dialCode: 594,
    format: "+594-#####-####",
    position: 324
  },
  {
    name: "French Polynesia",
    iso2: "PF",
    dialCode: 689,
    format: "+689-##-##-##",
    position: 2262
  },
  {
    name: "Gabon",
    iso2: "GA",
    dialCode: 241,
    format: "+241-#-##-##-##",
    position: 1157
  },
  {
    name: "Gambia",
    iso2: "GM",
    dialCode: 220,
    format: "+220(###)##-##",
    position: 817
  },
  {
    name: "Georgia",
    iso2: "GE",
    dialCode: 995,
    format: "+995(###)###-###",
    position: 1123
  },
  {
    name: "Germany",
    iso2: "DE",
    dialCode: 49,
    format: "+40 ### #######",
    position: 3452
  },
  {
    name: "Ghana",
    iso2: "GH",
    dialCode: 233,
    format: "+233(###)###-###",
    position: 2891
  },
  {
    name: "Gibraltar",
    iso2: "GI",
    dialCode: 350,
    format: "+350-###-#####",
    position: 341
  },
  {
    name: "Greece",
    iso2: "GR",
    dialCode: 30,
    format: "+30(###)###-####",
    position: 188
  },
  {
    name: "Greenland",
    iso2: "GL",
    dialCode: 299,
    format: "+299-##-##-##",
    position: 2347
  },
  {
    name: "Grenada",
    iso2: "GD",
    dialCode: 1473,
    format: "+1(473)###-####",
    position: 3316
  },
  {
    name: "Guadeloupe",
    iso2: "GP",
    dialCode: 590,
    format: "+590 #############",
    position: 511
  },
  {
    name: "Guam",
    iso2: "GU",
    dialCode: 1671,
    format: "+1(671)###-####",
    position: 3265
  },
  {
    name: "Guatemala",
    iso2: "GT",
    dialCode: 502,
    format: "+502 ####-####",
    position: 1208
  },
  {
    name: "Guinea",
    iso2: "GN",
    dialCode: 224,
    format: "+224-##-###-###",
    position: 3520
  },
  {
    name: "Guinea-Bissau",
    iso2: "GW",
    dialCode: 245,
    format: "+245-#-######",
    position: 2602
  },
  {
    name: "Guyana",
    iso2: "GY",
    dialCode: 592,
    format: "+592-###-####",
    position: 1038
  },
  {
    name: "Haiti",
    iso2: "HT",
    dialCode: 509,
    format: "+509 ####-####",
    position: 392
  },
  {
    name: "Honduras",
    iso2: "HN",
    dialCode: 504,
    format: "+504-####-####",
    position: 2959
  },
  {
    name: "Hong Kong",
    iso2: "HK",
    dialCode: 852,
    format: "+852 #### ####",
    position: 3707
  },
  {
    name: "Hungary",
    iso2: "HU",
    dialCode: 36,
    format: "+36(###)###-###",
    position: 902
  },
  {
    name: "Iceland",
    iso2: "IS",
    dialCode: 354,
    format: "+354 ### ####",
    position: 2704
  },
  {
    name: "India",
    iso2: "IN",
    dialCode: 91,
    format: "+91 #####-#####",
    position: 2245
  },
  {
    name: "Indonesia",
    iso2: "ID",
    dialCode: 62,
    format: "+62-##-###-##",
    position: 2653
  },
  {
    name: "Iran",
    iso2: "IR",
    dialCode: 98,
    format: "+98(###)###-####",
    position: 2738
  },
  {
    name: "Iraq",
    iso2: "IQ",
    dialCode: 964,
    format: "+964(###)###-####",
    position: 851
  },
  {
    name: "Ireland",
    iso2: "IE",
    dialCode: 353,
    format: "+353 ## #######",
    position: 2670
  },
  {
    name: "Israel",
    iso2: "IL",
    dialCode: 972,
    format: "+972-#-###-####",
    position: 426
  },
  {
    name: "Italy",
    iso2: "IT",
    dialCode: 39,
    format: "+39 ### ######",
    position: 154
  },
  {
    name: "Jamaica",
    iso2: "JM",
    dialCode: 1876,
    format: "+1(876)###-####",
    position: 2296
  },
  {
    name: "Japan",
    iso2: "JP",
    dialCode: 81,
    format: "+81 ### ## ####",
    position: 528
  },
  {
    name: "Jordan",
    iso2: "JO",
    dialCode: 962,
    format: "+962-#-####-####",
    position: 1905
  },
  {
    name: "Kazakhstan",
    iso2: "KZ",
    dialCode: 7,
    format: "+7 ### ###-##-##",
    position: 1565
  },
  {
    name: "Kenya",
    iso2: "KE",
    dialCode: 254,
    format: "+254-###-######",
    position: 3605
  },
  {
    name: "Kiribati",
    iso2: "KI",
    dialCode: 686,
    format: "+686-##-###",
    position: 477
  },
  {
    name: "Kuwait",
    iso2: "KW",
    dialCode: 965,
    format: "+965-####-####",
    position: 3435
  },
  {
    name: "Kyrgyzstan",
    iso2: "KG",
    dialCode: 996,
    format: "+996(###)###-###",
    position: 2143
  },
  {
    name: "Laos",
    iso2: "LA",
    dialCode: 856,
    format: "+856-##-###-###",
    position: 562
  },
  {
    name: "Latvia",
    iso2: "LV",
    dialCode: 371,
    format: "+371-##-###-###",
    position: 2619
  },
  {
    name: "Lebanon",
    iso2: "LB",
    dialCode: 961,
    format: "+961-#-###-###",
    position: 1616
  },
  {
    name: "Lesotho",
    iso2: "LS",
    dialCode: 266,
    format: "+266-#-###-####",
    position: 3010
  },
  {
    name: "Liberia",
    iso2: "LR",
    dialCode: 231,
    format: "+231-##-###-###",
    position: 2823
  },
  {
    name: "Libya",
    iso2: "LY",
    dialCode: 218,
    format: "+218-##-###-###",
    position: 137
  },
  {
    name: "Liechtenstein",
    iso2: "LI",
    dialCode: 423,
    format: "+423(###)###-####",
    position: 1276
  },
  {
    name: "Lithuania",
    iso2: "LT",
    dialCode: 370,
    format: "+370(###)##-###",
    position: 1446
  },
  {
    name: "Luxembourg",
    iso2: "LU",
    dialCode: 352,
    format: "+352(###)###-###",
    position: 1922
  },
  {
    name: "Macau",
    iso2: "MO",
    dialCode: 853,
    format: "+853-####-####",
    position: 3554
  },
  {
    name: "Macedonia (FYROM)",
    iso2: "MK",
    dialCode: 389,
    format: "+389-##-###-###",
    position: 1769
  },
  {
    name: "Madagascar",
    iso2: "MG",
    dialCode: 261,
    format: "+261-##-##-#####",
    position: 1667
  },
  {
    name: "Malawi",
    iso2: "MW",
    dialCode: 265,
    format: "+265-#-####-####",
    position: 2942
  },
  {
    name: "Malaysia",
    iso2: "MY",
    dialCode: 60,
    format: "+60 ##-####-####",
    position: 2517
  },
  {
    name: "Maldives",
    iso2: "MV",
    dialCode: 960,
    format: "+960-###-####",
    position: 800
  },
  {
    name: "Mali",
    iso2: "ML",
    dialCode: 223,
    format: "+223-##-##-####",
    position: 3469
  },
  {
    name: "Malta",
    iso2: "MT",
    dialCode: 356,
    format: "+356-####-####",
    position: 2041
  },
  {
    name: "Marshall Islands",
    iso2: "MH",
    dialCode: 692,
    format: "+692-###-####",
    position: 1463
  },
  {
    name: "Martinique",
    iso2: "MQ",
    dialCode: 596,
    format: "+596(###)##-##-##",
    position: 239
  },
  {
    name: "Mexico",
    iso2: "MX",
    dialCode: 52,
    format: "+52-##-##-####",
    position: 2755
  },
  {
    name: "Micronesia",
    iso2: "FM",
    dialCode: 691,
    format: "+691-###-####",
    position: 2313
  },
  {
    name: "Moldova",
    iso2: "MD",
    dialCode: 373,
    format: "+373-####-####",
    position: 3690
  },
  {
    name: "Monaco",
    iso2: "MC",
    dialCode: 377,
    format: "+377-##-###-###",
    position: 1191
  },
  {
    name: "Mongolia",
    iso2: "MN",
    dialCode: 976,
    format: "+976-##-##-####",
    position: 3503
  },
  {
    name: "Montenegro",
    iso2: "ME",
    dialCode: 382,
    format: "+382-##-###-###",
    position: 2976
  },
  {
    name: "Montserrat",
    iso2: "MS",
    dialCode: 1664,
    format: "+1(664)###-####",
    position: 749
  },
  {
    name: "Morocco",
    iso2: "MA",
    dialCode: 212,
    format: "+212-##-####-###",
    position: 3214
  },
  {
    name: "Mozambique",
    iso2: "MZ",
    dialCode: 258,
    format: "+258-##-###-###",
    position: 834
  },
  {
    name: "Myanmar (Burma)",
    iso2: "MM",
    dialCode: 95,
    format: "+95-###-###",
    position: 18
  },
  {
    name: "Namibia",
    iso2: "NA",
    dialCode: 264,
    format: "+264-##-###-####",
    position: 2534
  },
  {
    name: "Nauru",
    iso2: "NR",
    dialCode: 674,
    format: "+674-###-####",
    position: 2330
  },
  {
    name: "Nepal",
    iso2: "NP",
    dialCode: 977,
    format: "+977-##-###-###",
    position: 120
  },
  {
    name: "Netherlands",
    iso2: "NL",
    dialCode: 31,
    format: "+31 ## ########",
    position: 1888
  },
  {
    name: "New Caledonia",
    iso2: "NC",
    dialCode: 687,
    format: "+687-##-####",
    position: 1650
  },
  {
    name: "New Zealand",
    iso2: "NZ",
    dialCode: 64,
    format: "+64 ###-###-####",
    position: 2024
  },
  {
    name: "Nicaragua",
    iso2: "NI",
    dialCode: 505,
    format: "+505-####-####",
    position: 171
  },
  {
    name: "Niger",
    iso2: "NE",
    dialCode: 227,
    format: "+227-##-##-####",
    position: 715
  },
  {
    name: "Nigeria",
    iso2: "NG",
    dialCode: 234,
    format: "+234-##-###-##",
    position: 3418
  },
  {
    name: "Niue",
    iso2: "NU",
    dialCode: 683,
    format: "+683-####",
    position: 2840
  },
  {
    name: "Norfolk Island",
    iso2: "NF",
    dialCode: 672,
    format: "+672-###-###",
    position: 256
  },
  {
    name: "North Korea",
    iso2: "KP",
    dialCode: 850,
    format: "+850-###-###",
    position: 2415
  },
  {
    name: "Northern Mariana Islands",
    iso2: "MP",
    dialCode: 1670,
    format: "+1(670)###-####",
    position: 919
  },
  {
    name: "Norway",
    iso2: "NO",
    dialCode: 47,
    format: "+47 ### ## ###",
    position: 1089
  },
  {
    name: "Oman",
    iso2: "OM",
    dialCode: 968,
    format: "+968-##-###-###",
    position: 3384
  },
  {
    name: "Pakistan",
    iso2: "PK",
    dialCode: 92,
    format: "+92 ###-#######",
    position: 2772
  },
  {
    name: "Palau",
    iso2: "PW",
    dialCode: 680,
    format: "+680-###-####",
    position: 273
  },
  {
    name: "Palestine",
    iso2: "PS",
    dialCode: 970,
    format: "+970-##-###-####",
    position: 1548
  },
  {
    name: "Panama",
    iso2: "PA",
    dialCode: 507,
    format: "+507-###-####",
    position: 1106
  },
  {
    name: "Papua New Guinea",
    iso2: "PG",
    dialCode: 675,
    format: "+675(###)##-###",
    position: 1939
  },
  {
    name: "Paraguay",
    iso2: "PY",
    dialCode: 595,
    format: "+595(###)###-###",
    position: 3231
  },
  {
    name: "Peru",
    iso2: "PE",
    dialCode: 51,
    format: "+51(###)###-###",
    position: 1225
  },
  {
    name: "Philippines",
    iso2: "PH",
    dialCode: 63,
    format: "+63 ### ####",
    position: 2432
  },
  {
    name: "Poland",
    iso2: "PL",
    dialCode: 48,
    format: "+48 ###-###-###",
    position: 1514
  },
  {
    name: "Portugal",
    iso2: "PT",
    dialCode: 351,
    format: "+351-##-###-####",
    position: 664
  },
  {
    name: "Puerto Rico",
    iso2: "PR",
    dialCode: 1,
    format: "+1 (###) ###-####",
    position: 596
  },
  {
    name: "Qatar",
    iso2: "QA",
    dialCode: 974,
    format: "+974-####-####",
    position: 579
  },
  {
    name: "Réunion",
    iso2: "RE",
    dialCode: 262,
    format: "+262-#####-####",
    position: 324
  },
  {
    name: "Romania",
    iso2: "RO",
    dialCode: 40,
    format: "+40-##-###-####",
    position: 885
  },
  {
    name: "Russia",
    iso2: "RU",
    dialCode: 7,
    format: "+7 ### ###-##-##",
    position: 868
  },
  {
    name: "Rwanda",
    iso2: "RW",
    dialCode: 250,
    format: "+250(###)###-###",
    position: 3673
  },
  {
    name: "Samoa",
    iso2: "WS",
    dialCode: 685,
    format: "+685-##-####",
    position: 3163
  },
  {
    name: "San Marino",
    iso2: "SM",
    dialCode: 378,
    format: "+378-####-######",
    position: 2908
  },
  {
    name: "São Tomé & Príncipe",
    iso2: "ST",
    dialCode: 239,
    format: "+239-##-#####",
    position: 3299
  },
  {
    name: "Saudi Arabia",
    iso2: "SA",
    dialCode: 966,
    format: "+966-#-###-####",
    position: 52
  },
  {
    name: "Senegal",
    iso2: "SN",
    dialCode: 221,
    format: "+221-##-###-####",
    position: 2925
  },
  {
    name: "Serbia",
    iso2: "RS",
    dialCode: 381,
    format: "+381-##-###-####",
    position: 3401
  },
  {
    name: "Seychelles",
    iso2: "SC",
    dialCode: 248,
    format: "+248-#-###-###",
    position: 1327
  },
  {
    name: "Sierra Leone",
    iso2: "SL",
    dialCode: 232,
    format: "+232-##-######",
    position: 970
  },
  {
    name: "Singapore",
    iso2: "SG",
    dialCode: 65,
    format: "+65 ####-####",
    position: 35
  },
  {
    name: "Sint Maarten",
    iso2: "SX",
    dialCode: 1721,
    format: "+1(721)###-####",
    position: 3826
  },
  {
    name: "Slovakia",
    iso2: "SK",
    dialCode: 421,
    format: "+421(###)###-###",
    position: 3044
  },
  {
    name: "Slovenia",
    iso2: "SI",
    dialCode: 386,
    format: "+386-##-###-###",
    position: 1582
  },
  {
    name: "Solomon Islands",
    iso2: "SB",
    dialCode: 677,
    format: "+677-#####",
    position: 1361
  },
  {
    name: "Somalia",
    iso2: "SO",
    dialCode: 252,
    format: "+252-#-###-###",
    position: 1786
  },
  {
    name: "South Africa",
    iso2: "ZA",
    dialCode: 27,
    format: "+27-##-###-####",
    position: 3428
  },
  {
    name: "South Korea",
    iso2: "KR",
    dialCode: 82,
    format: "+82-##-###-####",
    position: 3078
  },
  {
    name: "South Sudan",
    iso2: "SS",
    dialCode: 211,
    format: "+211-##-###-####",
    position: 3775
  },
  {
    name: "Spain",
    iso2: "ES",
    dialCode: 34,
    format: "+34 ### ### ###",
    position: 1480
  },
  {
    name: "Sri Lanka",
    iso2: "LK",
    dialCode: 94,
    format: "+94-##-###-####",
    position: 3622
  },
  {
    name: "St. Barthélemy",
    iso2: "BL",
    dialCode: 590,
    format: "+590 ##########",
    position: 324
  },
  {
    name: "St. Helena",
    iso2: "SH",
    dialCode: 290,
    format: "+290 ##########",
    position: 630
  },
  {
    name: "St. Kitts & Nevis",
    iso2: "KN",
    dialCode: 1869,
    format: "+1(869)###-####",
    position: 103
  },
  {
    name: "St. Lucia",
    iso2: "LC",
    dialCode: 1758,
    format: "+1(758)###-####",
    position: 1837
  },
  {
    name: "St. Martin",
    iso2: "MF",
    dialCode: 590,
    format: "+590 ##########",
    position: 86
  },
  {
    name: "St. Pierre & Miquelon",
    iso2: "PM",
    dialCode: 508,
    format: "+508 ##########",
    position: 1378
  },
  {
    name: "St. Vincent & Grenadines",
    iso2: "VC",
    dialCode: 1784,
    format: "+1(784)###-####",
    position: 3588
  },
  {
    name: "Sudan",
    iso2: "SD",
    dialCode: 249,
    format: "+249-##-###-####",
    position: 443
  },
  {
    name: "Suriname",
    iso2: "SR",
    dialCode: 597,
    format: "+597-###-###",
    position: 3656
  },
  {
    name: "Swaziland",
    iso2: "SZ",
    dialCode: 268,
    format: "+268-##-##-####",
    position: 3129
  },
  {
    name: "Sweden",
    iso2: "SE",
    dialCode: 46,
    format: "+46 ## ### ## ##",
    position: 494
  },
  {
    name: "Switzerland",
    iso2: "CH",
    dialCode: 41,
    format: "+41 ## ### ## ##",
    position: 1718
  },
  {
    name: "Syria",
    iso2: "SY",
    dialCode: 963,
    format: "+963-##-####-###",
    position: 2449
  },
  {
    name: "Taiwan",
    iso2: "TY",
    dialCode: 886,
    format: "+886-####-####",
    position: 647
  },
  {
    name: "Tajikistan",
    iso2: "TJ",
    dialCode: 992,
    format: "+992-##-###-####",
    position: 222
  },
  {
    name: "Tanzania",
    iso2: "TZ",
    dialCode: 255,
    format: "+255-##-###-####",
    position: 3146
  },
  {
    name: "Thailand",
    iso2: "TH",
    dialCode: 66,
    format: "+66-##-###-###",
    position: 1242
  },
  {
    name: "Timor-Leste",
    iso2: "TL",
    dialCode: 670,
    format: "+670-###-####",
    position: 3843
  },
  {
    name: "Togo",
    iso2: "TG",
    dialCode: 228,
    format: "+228-##-###-###",
    position: 783
  },
  {
    name: "Tokelau",
    iso2: "TK",
    dialCode: 690,
    format: "+690-####",
    position: 3792
  },
  {
    name: "Tonga",
    iso2: "TO",
    dialCode: 676,
    format: "+676-#####",
    position: 1395
  },
  {
    name: "Trinidad & Tobago",
    iso2: "TT",
    dialCode: 1868,
    format: "+1(868)###-####",
    position: 545
  },
  {
    name: "Tunisia",
    iso2: "TN",
    dialCode: 216,
    format: "+216-##-###-###",
    position: 698
  },
  {
    name: "Turkey",
    iso2: "TR",
    dialCode: 90,
    format: "+90 ### ### ## ##",
    position: 2126
  },
  {
    name: "Turkmenistan",
    iso2: "TM",
    dialCode: 993,
    format: "+993-#-###-####",
    position: 3486
  },
  {
    name: "Turks & Caicos Islands",
    iso2: "TC",
    dialCode: 1649,
    format: "+1(649)###-####",
    position: 1701
  },
  {
    name: "Tuvalu",
    iso2: "TV",
    dialCode: 688,
    format: "+688-#####",
    position: 358
  },
  {
    name: "U.S. Virgin Islands",
    iso2: "VI",
    dialCode: 1340,
    format: "+1(340)###-####",
    position: 2381
  },
  {
    name: "Uganda",
    iso2: "UG",
    dialCode: 256,
    format: "+256(###)###-###",
    position: 1497
  },
  {
    name: "Ukraine",
    iso2: "UK",
    dialCode: 380,
    format: "+380(##)###-##-##",
    position: 2721
  },
  {
    name: "United Arab Emirates",
    iso2: "AE",
    dialCode: 971,
    format: "+971-#-###-####",
    position: 3061
  },
  {
    name: "United Kingdom",
    iso2: "GB",
    dialCode: 44,
    format: "+44 #### ######",
    position: 86
  },
  {
    name: "Uruguay",
    iso2: "UY",
    dialCode: 598,
    format: "+598-#-###-##-##",
    position: 3571
  },
  {
    name: "Uzbekistan",
    iso2: "UZ",
    dialCode: 998,
    format: "+998-##-###-####",
    position: 1293
  },
  {
    name: "Vanuatu",
    iso2: "VU",
    dialCode: 678,
    format: "+678-#####",
    position: 1633
  },
  {
    name: "Vatican City",
    iso2: "VA",
    dialCode: 39,
    format: "+39 ## #### ####",
    position: 3197
  },
  {
    name: "Venezuela",
    iso2: "VE",
    dialCode: 58,
    format: "+58(###)###-####",
    position: 1344
  },
  {
    name: "Vietnam",
    iso2: "VN",
    dialCode: 84,
    format: "+84-##-####-###",
    position: 1259
  },
  {
    name: "Wallis & Futuna",
    iso2: "WF",
    dialCode: 681,
    format: "+681-##-####",
    position: 324
  },
  {
    name: "Yemen",
    iso2: "YE",
    dialCode: 967,
    format: "+967-#-###-###",
    position: 2211
  },
  {
    name: "Zambia",
    iso2: "ZM",
    dialCode: 260,
    format: "+260-##-###-####",
    position: 2109
  },
  {
    name: "Zimbabwe",
    iso2: "ZW",
    dialCode: 263,
    format: "+263-#-######",
    position: 2109
  }
];
