// @flow weak

import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DangerIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";

const styles = theme => ({
  close: {
    color: theme.palette.grey.dark,
    margin: "0",
    borderRadius: "0",
    borderLeft: "1px solid #eee",
    alignSelf: "normal",
    transition: theme.transitions.create("all"),
    "&:hover": {
      backgroundColor: theme.palette.background.normal
    }
  },
  snackBar: {
    borderRadius: "2px",
    backgroundColor: "#fff",
    display: "flex",
    width: "50em",
    maxWidth: "80%",
    padding: "0",
    borderLeft: "6px solid transparent"
    // boxShadow: theme.shadow.heavy
  },
  snackBarContent: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    padding: "0px",
    color: "black"
  },
  snackBarMessage: {
    color: theme.palette.grey.dark,
    flexGrow: "1",
    padding: "1em"
  },
  snackBarSuccess: {
    borderLeftColor: "green"
  },
  snackBarDanger: {
    borderLeftColor: "red"
  },
  snackBarInfo: {
    borderLeftColor: "blue"
  },
  snackBarWarning: {
    borderLeftColor: "orange"
  },
  snackBarIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  snackBarIconSuccess: {
    color: "green"
  },
  snackBarIconDanger: {
    color: "red"
  },
  snackBarIconInfo: {
    color: "blue"
  },
  snackBarIconWarning: {
    color: "orange"
  }
});

class SnackbarMessage extends Component {
	state = {
		openSnackbar: false
  };

  handleRequestClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnackbar: false });
  };

  openSnackbar = () => {
    this.setState({ openSnackbar: true });
  };

  componentDidMount() {
    const message = this.props.message;
    if (message) {
      this.openSnackbar();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.allowUpdates && nextProps.message) {
      this.openSnackbar();
    }

    if (!nextProps.message) {
      this.setState({ openSnackbar: false });
    }
  }

  render() {
    const { classes, message, type } = this.props;
    var snackBarType = "";
    var icon = "";
    switch (type) {
      case "danger":
        snackBarType = classes.snackBarDanger;
        icon = (
          <DangerIcon
            className={classNames(
              classes.snackBarIcon,
              classes.snackBarIconDanger
            )}
          />
        );
        break;

      case "warning":
        snackBarType = classes.snackBarWarning;
        icon = (
          <WarningIcon
            className={classNames(
              classes.snackBarIcon,
              classes.snackBarIconWarning
            )}
          />
        );
        break;
      case "success":
        snackBarType = classes.snackBarSuccess;
        icon = (
          <CheckIcon
            className={classNames(
              classes.snackBarIcon,
              classes.snackBarIconSuccess
            )}
          />
        );
        break;
      default:
      case "info":
        snackBarType = classes.snackBarInfo;
        icon = (
          <InfoIcon
            className={classNames(
              classes.snackBarIcon,
              classes.snackBarIconInfo
            )}
          />
        );
        break;
    }

    const snackBarClass = classNames(classes.snackBar, snackBarType);
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={this.state.openSnackbar}
        autoHideDuration={500000}
        onClose={this.props.onClose || this.handleRequestClose}
        ContentProps={{
          classes: {
            root: snackBarClass,
            message: classes.snackBarContent
          },
          "aria-describedby": "message-id"
        }}
        message={
          <Fragment>
            {icon}
            <span className={classes.snackBarMessage}>{message}</span>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              disableRipple
              onClick={this.props.onClose || this.handleRequestClose}
            >
              <CloseIcon />
            </IconButton>
          </Fragment>
        }
      />
    );
  }
}

SnackbarMessage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { name: "SnackbarMessage" })(SnackbarMessage);
