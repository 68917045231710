import keyBy from "lodash/keyBy";
export default class Resource {
  constructor(opts) {
    // basics
    this.singularUppername = opts.singularName.toUpperCase();
    this.pluralUppername = opts.pluralName.toUpperCase();

    // fetching
    this.fetchingSingularConst = "FETCHING_" + this.singularUppername;
    this.fetchingSingularMetaConst = "FETCHING_META_" + this.singularUppername;
    this.fetchingPluralConst = "FETCHING_" + this.pluralUppername;

    // adding
    this.addingSingularConst = "ADDING_" + this.singularUppername;
    this.addingPluralConst = "ADDING_MULTIPLE_" + this.pluralUppername;

    // deleting
    this.deletingSingularConst = "DELETING_" + this.singularUppername;
    this.deletingPluralConst = "DELETING_MULTIPLE_" + this.pluralUppername;

    // downloading
    this.downloadingProgressConst =
      "DOWNLOADING_PROGRESS_" + this.singularUppername;
    this.downloadingSingularConst = "DOWNLOADING_" + this.singularUppername;
    this.downloadingPluralConst =
      "DOWNLOADING_MULTIPLE_" + this.pluralUppername;

    // errors
    this.errorAddingSingularConst =
      "ADDING_" + this.singularUppername + "_ERROR";
    this.errorAddingPluralConst =
      "ADDING_MULTIPLE_" + this.pluralUppername + "_ERROR";
    this.errorDeletingSingularConst =
      "DELETING_" + this.singularUppername + "_ERROR";
    this.errorDeletingPluralConst =
      "DELETING_MULTIPLE_" + this.pluralUppername + "_ERROR";
    this.errorDownloadingSingularConst =
      "DOWNLOADING_" + this.singularUppername + "_ERROR";
    this.errorDownloadingPluralConst =
      "DOWNLOADING_MULTIPLE_" + this.pluralUppername + "_ERROR";
    this.errorUploadingSingularConst =
      "UPLOADING_" + this.singularUppername + "_ERROR";
    this.errorUploadingPluralConst =
      "UPLOADING_MULTIPLE_" + this.pluralUppername + "_ERROR";
    this.errorUpdatingSingularConst =
      "UPDATING_" + this.singularUppername + "_ERROR";
    this.errorUpdatingPluralConst =
      "UPDATING_MULTIPLE_" + this.pluralUppername + "_ERROR";
    this.errorSingularConst = "FETCHING_" + this.singularUppername + "_ERROR";
    this.errorPluralConst =
      "FETCHING_MULTIPLE_" + this.pluralUppername + "_ERROR";

    // success
    this.successSingularConst =
      "FETCHING_" + this.singularUppername + "_SUCCESS";
    this.successSingularMetaConst =
      "FETCHING_META_" + this.singularUppername + "_SUCCESS";
    this.successPluralConst = "FETCHING_" + this.pluralUppername + "_SUCCESS";
    this.successAddingSingularConst =
      "ADDING_" + this.singularUppername + "_SUCCESS";
    this.successAddingPluralConst =
      "ADDING_MULTIPLE_" + this.pluralUppername + "_SUCCESS";
    this.successDeletingSingularConst =
      "DELETING_" + this.singularUppername + "_SUCCESS";
    this.successDeletingPluralConst =
      "DELETING_MULTIPLE_" + this.pluralUppername + "_SUCCESS";
    this.successDownloadingSingularConst =
      "DOWNLOADING_" + this.singularUppername + "_SUCCESS";
    this.successDownloadingPluralConst =
      "DOWNLOADING_MULTIPLE_" + this.pluralUppername + "_SUCCESS";
    this.successUploadingSingularConst =
      "UPLOADING_" + this.singularUppername + "_SUCCESS";
    this.successUploadingPluralConst =
      "UPLOADING_MULTIPLE_" + this.pluralUppername + "_SUCCESS";
    this.successUpdatingSingularConst =
      "UPDATING_" + this.singularUppername + "_SUCCESS";
    this.successUpdatingPluralConst =
      "UPDATING_MULTIPLE_" + this.pluralUppername + "_SUCCESS";

    // updates
    this.updatingSingularConst = "UPDATING_" + this.singularUppername;
    this.updatingPluralConst = "UPDATING_" + this.pluralUppername;

    // uploads
    this.uploadingProgressConst =
      "UPLOADING_PROGRESS_" + this.singularUppername;
    this.uploadingSingularConst = "UPLOADING_" + this.singularUppername;
    this.uploadingPluralConst = "UPLOADING_MULTIPLE_" + this.pluralUppername;

    // fetching thunks
    this.fetchingSingular = {
      isFetching: true,
      type: this.fetchingSingularConst
    };

    this.fetchingPlural = {
      isFetching: true,
      type: this.fetchingPluralConst
    };

    // adding thunks
    this.addingSingular = () => {
      return {
        type: this.addingSingularConst,
        isAdding: true
      };
    };
    this.addingPlural = response => {
      const records = keyBy(response.data, "id");
      const links = response.links;
			const meta = response.meta;
      return {
        isFetching: true,
        type: this.addingPluralConst,
        records,
        links,
        meta
      };
    };

    // success thunks
    this.successSingular = (ids, record) => {
      return {
        type: this.successSingularConst,
        lastUpdated: Date.now(),
        ids,
        record
      };
    };
    this.successSingularMeta = (ids, record) => {
      return {
        type: this.successSingularMetaConst,
        lastUpdated: Date.now(),
        ids,
        record
      };
    };
    this.successAddingSingular = (ids, record) => {
      return {
        type: this.successAddingSingularConst,
        lastUpdated: Date.now(),
        ids,
        record
      };
    };
    this.successDeletingSingular = ids => {
      return {
        type: this.successDeletingSingularConst,
        lastUpdated: Date.now(),
        ids
      };
    };
    this.successDeletingPlural = ids => {
      return {
        type: this.successDeletingPluralConst,
        lastUpdated: Date.now()
      };
    };
    this.successDownloadingSingular = () => {
      return {
        type: this.successDownloadingSingularConst,
        lastUpdated: Date.now()
      };
    };
    this.successDownloadingPlural = () => {
      return {
        type: this.successDownloadingPluralConst,
        lastUpdated: Date.now()
      };
    };
    this.successUploadingSingular = () => {
      return {
        type: this.successUploadingSingularConst,
        lastUpdated: Date.now()
      };
    };
    this.successUploadingPlural = () => {
      return {
        type: this.successUploadingPluralConst,
        lastUpdated: Date.now()
      };
    };
    this.successUpdatingSingular = record => {
      return {
        type: this.successUpdatingSingularConst,
        lastUpdated: Date.now(),
        record
      };
    };
    this.successUpdatingPlural = response => {
      return {
        type: this.successUpdatingPluralConst,
        lastUpdated: Date.now()
      };
    };
    this.successPlural = (ids, others) => {
      return {
        type: this.successPluralConst,
        lastUpdated: Date.now(),
        ids,
        others
      };
    };

    // deleting thunks
    this.deletingSingular = () => {
      return {
        type: this.deletingSingularConst
      };
    };
    this.deletingPlural = () => {
      return {
        type: this.deletingPluralConst
      };
    };

    // downloading thunks
    this.downloadingProgress = downloads => {
      return {
        type: this.downloadingProgressConst,
        downloads
      };
    };
    this.downloadingSingular = () => {
      return {
        type: this.downloadingSingularConst
      };
    };
    this.downloadingPlural = () => {
      return {
        type: this.downloadingPluralConst
      };
    };

    // uploading thunks
    this.uploadingProgress = uploads => {
      return {
        type: this.uploadingProgressConst,
        uploads
      };
    };
    this.uploadingSingular = () => {
      return {
        type: this.uploadingSingularConst
      };
    };
    this.uploadingPlural = () => {
      return {
        type: this.uploadingPluralConst
      };
    };

    // updating thunks
    this.updatingSingular = () => {
      return {
        type: this.updatingSingularConst
      };
    };
    this.updatingPlural = () => {
      return {
        type: this.updatingPluralConst
      };
    };

    // error thunks
    this.errorAddingSingular = error => {
      return {
        type: this.errorAddingSingularConst,
        error
      };
    };
    this.errorUpdatingSingular = error => {
      return {
        type: this.errorUpdatingSingularConst,
        error
      };
    };
    this.errorUpdatingPlural = error => {
      return {
        type: this.errorUpdatingPluralConst,
        error
      };
    };

    this.errorDeletingSingular = error => {
      return {
        type: this.errorDeletingSingularConst,
        error
      };
    };

    this.errorDeletingPlural = error => {
      return {
        type: this.errorDeletingPluralConst,
        error
      };
    };
    this.errorDownloadingSingular = error => {
      return {
        type: this.errorDownloadingSingularConst,
        error
      };
    };

    this.errorUploadingPlural = error => {
      return {
        type: this.errorUploadingPluralConst,
        error
      };
    };
    this.errorUploadingSingular = error => {
      return {
        type: this.errorUploadingSingularConst,
        error
      };
    };

    this.errorDownloadingPlural = error => {
      return {
        type: this.errorDownloadingPluralConst,
        error
      };
    };
    this.errorSingular = error => {
      return {
        type: this.errorSingularConst,
        error
      };
    };
    this.errorPlural = error => {
      console.error(error);
      return {
        type: this.errorPluralConst,
        error
      };
    };

    this.initialState = {
      currentId: 0,
      downloads: [],
      error: null,
      hasFetched: false,
      ids: [],
      isAdding: false,
      isDeleting: false,
      isDownloading: false,
      isFetching: false,
      isUpdating: false,
      isUploading: false,
      lastUpdated: 0,
      links: null,
      meta: null,
      params: null,
      resource: null,
      uploads: []
    };

    this.reducer = (state = this.initialState, action) => {
      switch (action.type) {
        case this.addingSingularConst:
          return {
            ...state,
            isAdding: true
          };
        case this.fetchingSingularConst:
        case this.fetchingPluralConst:
          return {
            ...state,
            isFetching: true
          };
        case this.deletingSingularConst:
        case this.deletingPluralConst:
          return {
            ...state,
            isDeleting: true
          };
        case this.downloadingProgressConst:
          return {
            ...state,
            downloads: action.downloads
          };
        case this.downloadingSingularConst:
        case this.downloadingPluralConst:
          return {
            ...state,
            isDownloading: true
          };
        case this.errorAddingSingularConst:
          return {
            ...state,
            isAdding: false,
            error: action.error
          };
        case this.errorDeletingSingularConst:
          return {
            ...state,
            isDeleting: false,
            error: action.error
          };
        case this.errorSingularConst:
        case this.errorPluralConst:
          return {
            ...state,
            hasFetched: true,
            isFetching: false,
            error: action.error
          };
        case this.gridChangeAction:
          return {
            ...state,
            [action.partialStateName]: action.partialStateValue
          };
        case this.resourceChangeAction:
          return {
            ...state,
            resource: action.resource
          };
        case this.successAddingSingularConst:
          return {
            ...state,
            currentId: action.record.id,
            lastUpdated: action.lastUpdated,
            isAdding: false,
            error: null,
            ids: action.ids,
            [action.record.id]: action.record
          };
        case this.successPluralConst:
          return {
            ...state,
            lastUpdated: action.lastUpdated,
            hasFetched: true,
            isFetching: false,
            error: null,
            ids: action.ids,
            ...(action.others || {})
          };
        case this.successSingularConst:
          return {
            ...state,
            currentId: action.record.id,
            lastUpdated: action.lastUpdated,
            hasFetched: true,
            isFetching: false,
            error: null,
            ids: action.ids,
            [action.record.id]: action.record
          };
        case this.successSingularMetaConst:
          return {
            ...state,
            currentId: action.record.id,
            lastUpdated: action.lastUpdated,
            hasFetched: true,
            isFetching: false,
            error: null,
            ids: action.ids,
            [action.record.id]: action.record
          };
        case this.addingPluralConst:
          return {
            ...state,
            ...action.records,
            links: action.links,
            meta: action.meta
          };
        case this.updatingSingularConst:
        case this.updatingPluralConst:
          return {
            ...state,
            isUpdating: true
          };
        case this.successUpdatingPluralConst:
          return {
            ...state,
            isUpdating: false,
            lastUpdated: action.lastUpdated
          };
        case this.uploadingProgressConst:
          return {
            ...state,
            uploads: action.uploads
          };
        case this.uploadingSingularConst:
        case this.uploadingPluralConst:
          return {
            ...state,
            isUploading: true
          };
        case this.successDeletingSingularConst:
          return {
            ...state,
            isDeleting: false,
            ids: action.ids,
            lastUpdated: action.lastUpdated
          };
        case this.successDeletingPluralConst:
          return {
            ...state,
            isDeleting: false,
            lastUpdated: action.lastUpdated
          };
        case this.successDownloadingSingularConst:
          return {
            ...state,
            isDownloading: false,
            lastUpdated: action.lastUpdated
          };
        case this.successDownloadingPluralConst:
          return {
            ...state,
            isDownloading: false,
            lastUpdated: action.lastUpdated
          };
        case this.successUploadingSingularConst:
          return {
            ...state,
            isUploading: false,
            lastUpdated: action.lastUpdated
          };
        case this.successUploadingPluralConst:
          return {
            ...state,
            isUploading: false,
            lastUpdated: action.lastUpdated
          };
        case this.errorUpdatingSingularConst:
        case this.errorUpdatingPluralConst:
          return {
            ...state,
            isUpdating: false,
            error: action.error
          };
        case this.successUpdatingSingularConst:
          return {
            ...state,
            lastUpdated: action.lastUpdated,
            isUpdating: false,
            error: null,
            [action.record.id]: action.record
          };
        default:
          return state;
      }
    };
  }
}
