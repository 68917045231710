import { getErrorMessage } from "../../helpers/utils";
import { fetchProperty } from "./properties";
import Resource from "./resource";

let resource = new Resource({
  singularName: "connectAccount",
  pluralName: "connectAccounts"
});

export function postConnectAccount(data) {
  return function(dispatch, getState, api) {
    return api.apiAxiosRequest(
      `stripe/connect_account`,
      "POST",
      data,
      {},
      true,
      true
    );
  };
}

export function fetchConnectAccounts() {
  return function(dispatch, getState, api) {
    dispatch(resource.fetchingPlural);
    api
      .apiRequest(`stripe/connect_acccounts`, "GET")
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export function setConnectAccountToProperty(id, propertyId) {
  return async function(dispatch, getState, api) {
    dispatch(resource.addingSingular());
    try {
      await api
        .rpcRequest("add-connect-account-to-property", {
          connect_account_id: id,
          property_id: propertyId
        })
        .then(response => {
          if (response.errors) {
            let error = getErrorMessage(response);
            dispatch(resource.errorAddingSingular(error));
            throw error;
          } else {
            dispatch(fetchProperty(propertyId));
          }
        });
    } catch (error) {
      dispatch(resource.errorAddingSingular(error));
      throw error;
    }
  };
}

export default resource.reducer;
