import app from "./app";
import bookings from "./bookings";
import connectAccounts from "./connectAccounts";
import datepicker from "./datepicker";
import feeds from "./feeds";
import follows from "./follows";
import groups from "./groups";
import guestMessages from "./guestMessages";
import images from "./images";
import inboxes from "./inboxes";
import invites from "./invites";
import messages from "./messages";
import notifications from "./notifications";
import personalProperties from "./personalProperties";
import properties from "./properties";
import propertyStays from "./propertyStays";
import settings from "./settings";
import trips from "./trips";
import users from "./users";
import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

export default combineReducers({
  app,
  bookings,
  follows,
  groups,
  guestMessages,
  images,
  invites,
  messages,
  properties,
  trips,
  settings,
  users,
  routing: routerReducer,
  form: formReducer,
  inboxes,
  datepicker,
  personalProperties,
  propertyStays,
  notifications,
  connectAccounts,
  feeds,
});
