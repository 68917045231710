import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../../redux/modules";
const configureStore = (preloadedState, apiClient) => {
  const store = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunk.withExtraArgument(apiClient))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../../redux/modules", () => {
      const nextRootReducer = require("../../redux/modules").default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
