import dasherize from "dasherize";

export function prepareAddress(values) {
  const address = {
    address1: values.address1,
    address2: values.address2,
    city: values.city,
    region: values.region,
    "postal-code": values.postalCode,
    "postal-code-suffix": values.postalCodeSuffix,
    country: values.country,
    location: values.location || null
  };
  return address;
}

export function prepareLocation(location) {
  let dasherizedLocation = {};
  Object.keys(location).forEach(key => {
    dasherizedLocation[dasherize(key)] = location[key];
  });
  return dasherizedLocation;
}
