import LogRocket from "logrocket";
import OwnCookies from "../../OwnCookies";
import { getMe } from "../../redux/modules/users";
import decode from "jwt-decode";

class Auth {
  constructor(cookies) {
    this.cookies = cookies;
  }
  setDispatch(dispatch) {
    this.dispatch = dispatch;
    // this.owb = new OurWebSocket(dispatch);
  }
  setGetState(getState) {
    this.getState = getState;
  }
  init = async () => {
    return await this.setReduxAuth();
  };

  setApiAxiosRequest(a) {
    this.apiAxiosRequest = a;
  }
  setHistory = (h) => {
    this.history = h;
  };
  setSessionStorageItem = (name, value) => {
    sessionStorage.setItem(name, value);
  };
  setReduxAuth = async () => {
    if (this.dispatch && this.isAuthenticated()) {
      return await this.dispatch(getMe())
        .then((response) => {
          if (response.data && response.data.email) {
            this.dispatch({
              type: "SET_AUTH",
              auth: {
                data: response.data,
                isAdmin: this.isAdmin(),
                isAuthenticated: true,
              },
            });
            this.dispatch({
              type: "APP_LOADED",
            });
          }
        })
        .catch((err) => console.log(err));
    }

    return this.dispatch({
      type: "APP_LOADED",
    });
  };
  setProfile = (data, isLogin) => {
    // Saves profile data to sessionStorage
    this.setSession(data);
    this.cookies.set("profile", JSON.stringify(data.profile));
    // sessionStorage.setItem("profile", JSON.stringify(data.profile));
    if (!isLogin) this.setReduxAuth();
    // set logrocket user data
    LogRocket.identify(data.profile.id, { ...data.profile });
  };

  completeLogin = (data) => {
    // set profile
    this.setProfile(data, true);
    if (typeof window !== "undefined") {
      // Inactive requires email confirmation
      if (!data.profile.active) {
        window.location.assign("/confirm-email");
      }

      // Active users only
      if (data.profile.active) {
				const code = sessionStorage.getItem("invite_code");
        if (code) {
          sessionStorage.removeItem("invite_code");
          return window.location.assign("/");
        }
        const url = sessionStorage.getItem("redirect_after_login");
        if (url && url !== "undefined") {
          sessionStorage.removeItem("redirect_after_login");
          return window.location.assign(url);
        } else {
          return window.location.assign(window.location.pathname);
        }
      }
    }
  };

  initFacebookMergeLogin = () => {
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          if (response.authResponse) {
            this.apiAxiosRequest(
              "users/facebook-merge",
              "POST",
              {
                ...response.authResponse,
              },
              {},
              true
            ).then(({ data, status }) => {
              if (status === 200) {
                this.setProfile(data);
                resolve(true);
              } else {
                reject(false);
              }
            });
          }
        } else {
          window.FB.login(
            (response) => {
              if (response.authResponse) {
                this.apiAxiosRequest(
                  "users/facebook-merge",
                  "POST",
                  {
                    ...response.authResponse,
                  },
                  {},
                  true
                ).then(({ data, status }) => {
                  if (status === 200) {
                    this.setProfile(data);
                    resolve(true);
                  } else {
                    reject(false);
                  }
                });
              } else {
                reject();
                //user hit cancel button
                console.log("User cancelled login or did not fully authorize.");
              }
            },
            {
              scope: "public_profile, email, user_friends",
            }
          );
        }
      });
    });
  };

  completeFacebookLogin = (response) => {
    // The user accepts our request.
    let code;
    if (typeof window !== "undefined") {
      code = sessionStorage.getItem("invite_code");
    }

    this.apiAxiosRequest("auth/facebook", "POST", {
      code,
      ...response.authResponse,
    }).then(({ data, status }) => {
      if (status === 200) {
        this.completeLogin(data);
      }
    });
  };

  initGoogleLogin = (response) => {
    if (response.tokenId) {
      let code;
      if (typeof window !== "undefined") {
        code = sessionStorage.getItem("invite_code");
      }

      this.apiAxiosRequest("auth/google", "POST", {
        code,
        tokenId: response.tokenId,
      }).then(({ data, status }) => {
        if (status === 200) {
          this.completeLogin(data);
        }
      });
    }
  };

  initFacebookLogin = () => {
    window.FB.getLoginStatus((response) => {
      if (response.status === "connected") {
        this.completeFacebookLogin(response);
      } else {
        window.FB.login(
          (response) => {
            if (response.authResponse) {
              this.completeFacebookLogin(response);
            } else {
              //user hit cancel button
              console.log("User cancelled login or did not fully authorize.");
            }
          },
          {
            scope: "public_profile, email, user_friends",
          }
        );
      }
    });
  };

  getProfile() {
    if (this && this.getState) {
      const s = this.getState();
      return (s.app && s.app.auth && s.app.auth.data) || {};
    }
    return {};
    // Retrieves the profile data from sessionStorage
    // const profile = sessionStorage.getItem("profile");
    // return profile ? JSON.parse(sessionStorage.profile) : {};
  }

  getRole() {
    const profile = this.getProfile();
    return profile.role || null;
  }

  setSession = (authResult) => {
    // Set the time that the access token will expire at
    if (authResult.profile.active) {
      let expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime()
      );
      this.cookies.set("access_token", authResult.accessToken);
      this.cookies.set("expires_at", expiresAt);
      this.cookies.set("profile", JSON.stringify(authResult.profile));
      //Token has changed, reload WS

      // this.owb.loadWS();
    }
  };

  removeSession = () => {
    // Clear access token and ID token from local storage
    this.cookies.delete("access_token");
    this.cookies.delete("expires_at");
    this.cookies.delete("profile");
    // user is now logged out
    // navigate to the home route
    window.location.href = "/";
  };

  logout = () => {
    window.FB.getLoginStatus((response) => {
      if (response.status === "connected") {
        window.FB.logout(function (response) {});
      }
      this.removeSession();
    });
  };

  forgotPasswordRequest = async (values) => {
    return await this.apiAxiosRequest("auth/forgot", "POST", {
      email: values.email,
    })
      .then(({ data, status }) => {
        if (status === 200) {
          return true;
        }
      })
      .catch((error) => {
        console.log(error.response);
        throw error.response.data.errors;
      });
  };

  resetPasswordRequest = async (values, code) => {
    return await this.apiAxiosRequest("auth/reset", "POST", {
      password: values.password,
      code: code,
    })
      .then(({ data, status }) => {
        if (status === 200) {
          return true;
        }
      })
      .catch((error) => {
        console.log(error.response);
        throw error.response.data.errors;
      });
  };

  resendConfirmEmailRequest = async (email) => {
    return await this.apiAxiosRequest("auth/resend-confirm-email", "POST", {
      email,
    })
      .then(({ status }) => {
        if (status === 200) {
          return true;
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        throw error.response.data.errors;
      });
  };

  confirmEmailRequest = async (code) => {
    return await this.apiAxiosRequest("auth/confirm-email", "POST", {
      code,
    })
      .then(({ data, status }) => {
        if (status === 200) {
          this.completeLogin(data);
          return true;
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        throw error.response.data.errors;
      });
  };

  signup = async (values) => {
    let code;
    if (typeof window !== "undefined") {
      code = sessionStorage.getItem("invite_code");
    }

    return await this.apiAxiosRequest("auth/signup", "POST", {
      code,
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      password: values.password,
    })
      .then(({ data, status }) => {
        if (status === 200) {
          this.completeLogin(data);
          return true;
        }
      })
      .catch((error) => {
        throw error.response.data.errors;
      });
  };

  login = async (values) => {
    let code;
    if (typeof window !== "undefined") {
      code = sessionStorage.getItem("invite_code");
    }
    return await this.apiAxiosRequest("auth/login", "POST", {
      code: code,
      email: values.email,
      password: values.password,
    })
      .then(({ data, status }) => {
        if (status === 200) {
          this.completeLogin(data);
          return true;
        }
      })
      .catch((error) => {
        throw error.response.data.errors;
      });
  };

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.cookies.get("expires_at") || 0;
    return new Date().getTime() < expiresAt;
  };

  isAdmin = () => {
    if (!this.isAuthenticated()) {
      return false;
    }
    // check for admin role for tenancy
    const role = this.getRole();
    return role === "admin";
  };

  getTokenExpirationDate(token) {
    const decoded = decode(token);
    if (!decoded.exp) {
      return null;
    }

    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token, redirect) {
    const date = this.getTokenExpirationDate(token);
    const offsetSeconds = 0;
    if (date === null) {
      return false;
    }
    const isExpired = !(
      date.valueOf() >
      new Date().valueOf() + offsetSeconds * 1000
    );
    if (isExpired && redirect) {
      this.history.push({
        pathname: "/",
        query: { message: "expired" },
      });
    }
    return isExpired;
  }
  unauthorized() {
    this.history.push({ pathname: "/unauthorized" });
  }
  getToken() {
    if (this.cookies) return this.cookies.get("access_token");
  }
}

export function getToken() {
  console.warn("CALLED GET TOKEN");
  return false;
  let cookies = new OwnCookies();
  if (cookies) return cookies.get("access_token");
}

export function getTokenExpirationDate(token) {
  const decoded = decode(token);
  if (!decoded.exp) {
    return null;
  }

  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp);
  return date;
}

export function isTokenExpired(token) {
  const date = getTokenExpirationDate(token);
  const offsetSeconds = 0;
  if (date === null) {
    return false;
  }
  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}

export default Auth;
