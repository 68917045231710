import ExternalLink from "@material-ui/core/Link";	
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";

import Hidden from "@material-ui/core/Hidden";

import heartIcon from "../../static/assets/icons/heart-icon.svg";
import footerLogo from "../../static/assets/logo/privateporch-logo-reversed.png";
import common from "@material-ui/core/colors/common";
import { Typography, Link } from "@material-ui/core";
const { white } = common;

export const styles = (theme) => ({
	root: {},
	whiteText: {
		color: "white !important",
	},
  bold: {
    fontWeight: "bold",
	},
	branding: {
		display: "inherit",
		flexDirection: "row",
	},
  divider: {
    backgroundColor: theme.palette.background.vdark,
    marginTop: 32,
    marginBottom: 31,
  },
  exLink: {
    paddingRight: 20,
  },
  landingFooter: {
    flexDirection: "column",
    marginTop: 0,
    textAlign: "center",
    "& img, & p": {
      margin: "1rem auto",
    },
  },
  heartIcon: {
    color: theme.palette.secondary.main,
    paddingLeft: 4,
    paddingRight: 4,
  },
  socialIcon: {
    height: 40,
    width: 40,
    "--color-1": theme.palette.primary.dark,
    "--color-2": theme.palette.secondary.main,
    "&:hover": {
      "--color-1": white,
      "--color-2": theme.palette.text.main,
    },
  },
  socialEndIcon: {
    color: theme.palette.secondary.main,
  },
  links: {
    display: "flex",
    alignItems: "stretch",
    paddingTop: 16,
  },
  link: {
    color: "white !important",
    fontSize: 16,
    textDecoration: "underline",
  },
  linkSpacer: {
    width: 37,
  },
  linkTop: {
    color: white,
    fontSize: 16,
    fontWeight: "bold",
    textDecoration: "none",
  },
  linkTopSpacer: {
    width: 40,
  },
  footerLogo: {
    width: 200,
		color: white,
		marginLeft: 10,
  },
  footer: {
    display: "flex",
    width: "100%",
  },
  footerTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: theme.palette.primary.main,
    paddingLeft: 500,
    paddingRight: 500,
		paddingBottom: 50,
    paddingTop: 50,
  },
  footerBottom: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 65,
    backgroundColor: theme.palette.background.vdark,
    color: white,
    fontSize: 16,
    paddingLeft: 200,
    paddingRight: 200,
    width: "100%",
  },
  footerNav: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: white,
    fontSize: 16,
  },
  footerNavTextWrapper: {
    paddingBottom: 8,
  },
  footerNavText: {
    marginRight: 8,
  },
  [theme.breakpoints.down("md")]: {
    footerTop: {
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%",
    },
    footerBottom: {
      paddingLeft: "10%",
      paddingRight: "10%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    footerNavTextWrapper: {
      paddingBottom: 0,
    },
    footerNav: {
      display: "inline-block",
      fontSize: 16,
      paddingTop: 0,
      paddingBottom: 0,
    },
    footerTop: {
      paddingLeft: 20,
			paddingRight: 20,
			paddingBottom: 20,
			flexDirection:"column",
			alignItems: "center",
    },
    footerBottom: {
      display: "inline-block",
      height: "auto",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 32,
      paddingBottom: 32,
    },
    footerBottomLinks: {
      paddingTop: 32,
    },
    links: {
      paddingTop: 0,
    },
    socialIcons: {
      paddingBottom: 32,
		},
		branding: {
			flexDirection: "column",
			alignItems: "center",
			paddingBottom: 20
		}
  },
});

class AppFooter extends Component {
  render() {
    const { classes, isAuthenticated } = this.props;
    const currentYear = new Date().getFullYear();
    
    if (isAuthenticated) {
      return null;
    } else
      return (
        <div className={classes.root}>
          <div className={classes.footerTop}>
            <div className={classes.branding}>
							<Typography className={classes.whiteText}>Powered by</Typography>
              <img
                src={footerLogo}
                alt="Private Porch logo"
                className={classes.footerLogo}
              />
            </div>
						<Hidden smDown>
							<div className={classes.whiteText}> - </div>
						</Hidden>
						<div>
							<Typography>
								<Link href="https://privateporch.com" onClick={(e)=> e.preventDefault} className={classes.link}>
									Join the Book Direct Revolution
								</Link>
							</Typography>
						</div>
          </div>
          <div className={classes.footerBottom}>
            <div>
              &copy; Private Porch {currentYear} &mdash; Made with
              <img
                alt="heart icon"
                src={heartIcon}
                className={classes.heartIcon}
              />
              in Portland, OR.
            </div>
            <div className={classes.footerBottomLinks}>
              <div className={classes.links}>
                <Link className={classes.link} to="/terms-of-service">
                  Terms
                </Link>
                <div className={classes.linkSpacer} />
                <Link className={classes.link} to="/privacy-policy">
                  Privacy
                </Link>
								<div className={classes.linkSpacer} />
								<ExternalLink className={classes.link} href="https://www.facebook.com/groups/privateporch">
                  Support
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default withStyles(styles, { name: "AppFooter" })(AppFooter);
