import { prepareAddress, prepareLocation } from "../../helpers/prepareJson";
import { getErrorMessage, geoLocation } from "../../helpers/utils";
import Resource from "./resource";
import dasherize from "dasherize";
import qs from "qs";
let resource = new Resource({
  singularName: "property",
  pluralName: "properties",
});

export function updateAddressLocation(submitValues) {
  return async function (dispatch, getState, api) {
    let addressResource = null;
    // create or update address location
    let location = "";
    if (submitValues.address.location) {
      location = prepareLocation(submitValues.address.location);
      submitValues.address.location = await api.createResource(
        "location",
        location
      );
    } else {
      location = await geoLocation(
        `${submitValues.address.address1} ${submitValues.address.city} ${submitValues.address.region} ${submitValues.address.postalCode}`
      );
      if (location) {
        submitValues.address.location = await api.createResource(
          "location",
          dasherize(location)
        );
      }
    }

    // create or update address
    let address = prepareAddress(submitValues.address);

    if (submitValues.address.id) {
      address["id"] = submitValues.address.id;
      addressResource = await api.updateResource("address", address);
    } else {
      addressResource = await api.createResource("address", address);
    }

    return addressResource;
  };
}

export function addHostToProperty(data) {
  return async function (dispatch, getState, api) {
    return await api.rpcRequest("add-host", data);
  };
}

export function getPropertyHosts(propertyId) {
  return async function (dispatch, getState, api) {
    return await api.apiRequest(`properties/${propertyId}/hosts`, "GET");
  };
}

export function getAmenities() {
  return async function (dispatch, getState, api) {
    return await api.apiRequest(`amenities`, "GET");
  };
}

export function setAmenities(data) {
  return async function (dispatch, getState, api) {
    return await api.rpcRequest("set-amenities", data);
  };
}

export function fetchNewProperties(data = {}) {
  return function (dispatch, getState, api) {
    dispatch(resource.fetchingPlural);
    const query = qs.stringify({
      "page[size]": 10,
      ...((data && data.query) || {}),
    });
    let news = [];
    return api
      .apiRequest(`properties/new?${query}`, "GET")
      .then((response) => {
        dispatch(resource.addingPlural(response));
        news = response.data.map((p) => p.id);
        return news;
      })
      .then((newIds) => {
        const prop = getState().properties;
        const news = data.more ? [...prop.news, ...newIds] : newIds;

        dispatch(resource.successPlural(prop.ids, { news }));
      })
      .catch((error) => dispatch(resource.errorPlural(error)));
  };
}

export function fetchSearchProperties(data = {}) {
  return function (dispatch, getState, api) {
    dispatch(resource.fetchingPlural);
    const query = qs.stringify({
      "page[size]": 10,
      ...((data && data.query) || {}),
    });
    let news = [];
    return api
      .apiRequest(`properties/search?${query}`, "GET")
      .then((response) => {
        dispatch(resource.addingPlural(response));
        news = response.data.map((p) => p.id);
        return news;
      })
      .then((newIds) => {
        const prop = getState().properties;
        const search = data.more ? [...prop.search, ...newIds] : newIds;

        dispatch(resource.successPlural(prop.ids, { search }));
      })
      .catch((error) => dispatch(resource.errorPlural(error)));
  };
}

export function fetchProperties() {
  return function (dispatch, _, api) {
    dispatch(resource.fetchingPlural);
    let ownPropertiesIds = [];
    let ids = [];
    let news = [];
    return api
      .apiRequest(`properties?includes=hosts`, "GET")
      .then((response) => {
        dispatch(resource.addingPlural(response));
        ids = response.data.map((p) => p.id);
        ownPropertiesIds = response.data
          .map((p) => (p.isHost && p.active ? p.id : false))
          .filter(Boolean);
        return response;
        // return api.apiRequest(`properties/new`, "GET");
      })
      .then((response) => {
        dispatch(resource.addingPlural(response));
        news = response.data.map((p) => p.id);
        return news;
      })
      .then((news) => {
        dispatch(resource.successPlural(ids, { ownPropertiesIds, news }));
      })
      .catch((error) => dispatch(resource.errorPlural(error)));
  };
}

export function fetchProperty(id) {
  return function (dispatch, getState, api) {
    dispatch(resource.fetchingSingular);
    return api
      .apiRequest(`properties/${id}`, "GET")
      .then((response) => {
        dispatch(resource.successSingular([response.data.id], response.data));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch(resource.errorSingular(error));
      });
  };
}

export function fetchPropertyBySlug(slug) {
  return function (dispatch, getState, api) {
    dispatch(resource.fetchingSingular);
    return api
      .apiRequest(`properties/slug/${slug}`, "GET")
      .then((response) => {
        dispatch(resource.successSingular([response.data.id], response.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(resource.errorSingular(error));
      });
  };
}

export function addProperty(values) {
  return async function (dispatch, getState, api) {
    dispatch(resource.addingSingular());
    const ids = getState().properties.ids;
    try {
      const property = await api.createResource("property", values);
      if (property) {
        ids.unshift(property.id);
        dispatch(resource.successAddingSingular(ids, property));
      }
      return property;
    } catch (error) {
      dispatch(resource.errorAddingSingular(error));
      throw error;
    }
  };
}

export function updateProperty(values) {
  return function (dispatch, getState, api) {
    dispatch(resource.updatingSingular());
    const property = api
      .updateResource("property", values)
      .then((response) => {
        dispatch(resource.successUpdatingSingular(response));
        return response;
      })
      .catch((error) => {
        dispatch(resource.errorUpdatingSingular(error));
        throw error;
      });
    return property;
  };
}

export function updatePropertyRequest(values) {
  return function (dispatch, getState, api) {
    dispatch(resource.successUpdatingSingular(values));
  };
}

export function deleteProperty(id) {
  return async function (dispatch, getState, api) {
    dispatch(resource.deletingSingular());
    try {
      await api.apiRequest(`properties/${id}`, "DELETE").then((response) => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorDeletingSingular(error));
          throw error;
        } else {
          dispatch(removeProperty(id));
        }
      });
    } catch (error) {
      dispatch(resource.errorDeletingSingular(error));
      throw error;
    }
  };
}

export function removeProperty(id) {
  return function (dispatch, getState, api) {
    const ids = getState().properties.ids;
    const i = ids.indexOf(id.toString());
    if (i !== -1) {
      ids.splice(i, 1);
    }
    dispatch(resource.successDeletingSingular(ids));
  };
}
export function sortImages(propertyId, userId) {
  return async function (dispatch, getState, api) {
    dispatch(resource.updatingPlural());
    try {
      await api
        .rpcRequest("property-add-friend", {
          property_id: propertyId,
          user_id: userId,
        })
        .then((response) => {
          if (response.errors) {
            let error = getErrorMessage(response);
            dispatch(resource.errorUpdatingPlural(error));
            throw error;
          }
        });
    } catch (error) {
      dispatch(resource.errorUpdatingPlural(error));
      throw error;
    }
  };
}
export default resource.reducer;
