import Resource from "./resource";
import qs from "qs";
let resource = new Resource({
  singularName: "feed",
  pluralName: "feeds"
});

export function fetchFeed(data = {}) {
  return function(dispatch, getState, api) {
    dispatch(resource.fetchingPlural);

    const query = qs.stringify({
      "page[size]": 10,
      ...((data && data.query) || {})
    });
    return api
      .apiRequest(`feed?${query}`, "GET", {})
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        const newIds = data.more ? [...getState().feeds.ids, ...ids] : ids;
        dispatch(resource.successPlural(newIds));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export function addFeedFromPushNotification(feed) {
  return function(dispatch, getState, api) {
    const ids = getState().feeds.ids;
    ids.unshift(feed.id);

    dispatch(resource.addingPlural({ data: [feed] }));
    dispatch(resource.successPlural(ids));
  };
}

export default resource.reducer;
