import Resource from "./resource";

let resource = new Resource({
  singularName: "notification",
  pluralName: "notifications"
});

export function fetchNotifications() {
  return function(dispatch, getState, api) {
    dispatch(resource.fetchingPlural);
    api
      .apiRequest(`notifications`, "GET")
      .then(response => {
        let ids = [];
        let counter = 0;
        response.data = response.data.map(r => {
          ids.push(r.id);
          if (!r.viewed) counter = counter + 1;
          return r;
        });

        dispatch(resource.addingPlural(response));
        dispatch({ type: "APP_SET_NOTIFICATION_COUNTER", counter: counter });
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export function readAllNotifications() {
  return function(dispatch, getState, api) {
    api.apiRequest(`notifications/read`, "GET");
  };
}

export default resource.reducer;
