import { getErrorMessage } from "../../helpers/utils";
import Resource from "./resource";
import dasherize from "dasherize";
let resource = new Resource({
  singularName: "trip",
  pluralName: "trips"
});

export function getCards() {
  return async function(dispatch, getState, api) {
    return await api.apiRequest(`cards`, "GET", {});
  };
}

export function makePayment(data) {
  return async function(dispatch, getState, api) {
    return await api.apiAxiosRequest(
      `stripe/payment`,
      "POST",
      data,
      {},
      true,
      true
    );
  };
}

export function getTripById(id) {
  return async function(dispatch, getState, api) {
    return await api.apiRequest(`inbox/${id}`, "GET", {});
  };
}
export function getPricing(values) {
  return function(dispatch, getState, api) {
    return api.apiAxiosRequest(
      `trips/pricing`,
      "POST",
      { data: api.serializeResource("trip", dasherize(values)) },
      {},
      true,
      true
    );
  };
}

export function fetchTrip(id) {
  return function(dispatch, getState, api) {
    dispatch(resource.fetchingSingular);
    return api
      .apiRequest(`inbox/${id}`, "GET")
      .then(response => {
        dispatch(resource.successSingular([response.data.id], response.data));
      })
      .catch(error => {
        dispatch(resource.errorSingular(error));
      });
  };
}

export function updateTrip(values) {
  return function(dispatch, getState, api) {
    dispatch(resource.updatingSingular());
    const trip = api
      .updateResource("trip", values)
      .then(response => {
        dispatch(resource.successUpdatingSingular(response));
        return response;
      })
      .catch(error => {
        dispatch(resource.errorUpdatingSingular(error));
        throw error;
      });
    return trip;
  };
}

export function deleteTrip(id) {
  return async function(dispatch, getState, api) {
    dispatch(resource.deletingSingular());
    try {
      await api.apiRequest(`trips/${id}`, "DELETE").then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorDeletingSingular(error));
          throw error;
        }
      });
    } catch (error) {
      dispatch(resource.errorDeletingSingular(error));
      throw error;
    }
  };
}

export function addTrip(values) {
  return async function(dispatch, getState, api) {
    dispatch(resource.addingSingular());
    const ids = getState().trips.ids;
    try {
      const trip = await api.createResource("trip", values);
      if (trip) {
        ids.unshift(trip.id);
        dispatch(resource.successAddingSingular(ids, trip));
      }
      return trip;
    } catch (error) {
      dispatch(resource.errorAddingSingular(error));
      throw error;
    }
  };
}

export default resource.reducer;
