import ClientApi from "./apis/ClientApi";
import configureStore from "./common/store/configureStore";
import MainApp from "./components/App/App";
import Auth from "./components/Auth/Auth";
import OurWebSocket from "./OurWebSocket";
import Cookies from "./OwnCookies";
import Routes from "./routes";
import getTheme from "./theme";
import { ensureReady, After } from "@jaredpalmer/after";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import React from "react";
import { hydrate } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;
const additionalData = window.__ADDITIONAL_DATA__;
let cookies = new Cookies();
const auth = new Auth(cookies);
const clientApi = new ClientApi(auth);
const store = configureStore(preloadedState, clientApi);
auth.setDispatch(store.dispatch);
auth.setGetState(store.getState);
auth.init();
const routes = Routes(auth);

const ws = new OurWebSocket(auth.getToken());
ws.setDispatch(store.dispatch);
ws.setApi(clientApi);

function Main(props) {
  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  let data = {
    ...props.data,
    auth: auth,
    store: store,
    isMobile: additionalData.isMobile
  };
  return (
    <BrowserRouter>
      <StripeProvider apiKey={process.env.RAZZLE_APP_STRIPE_PUBLIC}>
        <Provider store={store}>
          <ThemeProvider theme={getTheme(data.isMobile ? "sm" : "lg")}>
            <CssBaseline />
            <MainApp auth={auth} isMobile={data.isMobile}>
              <After data={data} routes={routes} store={store} auth={auth} />
            </MainApp>
          </ThemeProvider>
        </Provider>
      </StripeProvider>
    </BrowserRouter>
  );
}

function renderApp() {
  ensureReady(routes).then(data => {
    return hydrate(<Main data={data} />, document.getElementById("root"));
  });
}

renderApp();
if (module.hot) {
  module.hot.accept("./routes", renderApp);
}
