import { withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";

const styles = theme => ({});

function HomeHeartLight(props) {
  return (
    <SvgIcon {...props}>
      <svg
        aria-hidden="true"
        data-prefix="fal"
        data-icon="home-heart"
        className="svg-inline--fa fa-home-heart fa-w-18"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        <path
          fill="currentColor"
          d="M573.5 219.9L512 170.4V72c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v72.6L310.6 8c-13.2-10.7-32.1-10.7-45.2 0L2.5 219.9c-2.9 2.4-3.4 6.6-1 9.5l14.2 17.5c2.4 2.9 6.6 3.4 9.6 1L64 216.7V496c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16V216.8l38.8 31.3c2.9 2.4 7.2 1.9 9.6-1l14.2-17.5c2.3-3 1.7-7.3-1.1-9.7zM480 480H96V190.9L283.7 39.5c2.5-2 6-2 8.4 0L480 191v289zM276.9 201.5c-26.6-27.9-72.5-35.9-104.9-7.7-35.3 30.6-37.2 85.6-5.6 118.7l108.9 114.1c7 7.4 18.4 7.4 25.5 0l108.9-114.1c31.6-33.2 29.8-88.1-5.6-118.7-41.9-36.3-89.5-8.4-104.9 7.7L288 213.2l-11.1-11.7zm44.8 22.3c8.7-9.2 37.5-26.8 61.9-5.7 23.8 20.6 20.2 54.5 3.4 72.1L288 394l-99-103.8c-16.7-17.5-20.4-51.6 3.4-72.1 22.2-19.3 50-6.8 61.9 5.7l33.7 35.3 33.7-35.3z"
        />
      </svg>
    </SvgIcon>
  );
}

export default withStyles(styles)(HomeHeartLight);
