import React from "react";

const NoMatch = () => {
  return (
    <div className="section">
      <div style={{ margin: "auto", textAlign: "center", marginTop: 20 }}>
        <img
          alt="404 cone"
          src="/images/404cone.png"
          style={{ maxWidth: 400, marginBottom: 20 }}
        />
        <h2>The cones you are looking for aren't here. Our apologies.</h2>
      </div>
    </div>
  );
};

export default NoMatch;
